import { Component, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UserNotification } from 'src/app/models/notification/notification.modal';
import { GlobalVariablesService } from 'src/app/services/generic/global-constants.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserAccountService } from 'src/app/services/security/user-account.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  
  notificationsLoaded: UserNotification[] = [];
  notifications: UserNotification[] = [];
  isLoading = false;
  showingUnread = false;
  countNotifications = 0;
  userId: number;
  currentPage:number = 0;
  pageSize:number = 10;
  virtualPages: number[] = [];
  private notificationsSub: Subscription;
  private countUnreadSub: Subscription;
  private readonly _destroying$ = new Subject<void>();

  notificationScroll: number;

  constructor(
    private notificationService: NotificationService,
    private userAccountService: UserAccountService,
    private globalVariablesService: GlobalVariablesService
  ) { }

  ngOnInit(): void {
    this.notificationsSub = this.notificationService.notifications.subscribe(notifs => {
      this.notificationsLoaded = notifs;
      this.notifications = this.notificationsLoaded;
    });
    this.countUnreadSub = this.notificationService.countUnread.subscribe(count => {
      this.countNotifications = count;
    });
    this.userAccountService.selectedUserAccount$.pipe(takeUntil(this._destroying$)).subscribe(ua => {
      if(ua && ua.userId)
        {
          this.userId = ua.userId;
        }
      });

    // Set notification automatic scroll
    window.onscroll = () => {
      if(window.scrollY < 100 && !this.globalVariablesService.isMobileSize) {
        this.notificationScroll = window.scrollY;
      }
    }
  }

  onfilterUpdate(){
    if(this.showingUnread)
    {
      this.notifications = this.notifications.filter(n => !n.dismissedDate);
    }
    else {
      this.notifications = this.notificationsLoaded;
    }
  }

  changeShowUnread(){
    this.showingUnread = !this.showingUnread;
    this.onfilterUpdate();
  }

  markAllAsRead(){
    this.notificationService.markAllAsRead(this.userId).subscribe(() => {
      this.notificationService.fetchNotifications(this.userId, this.pageSize, 0, { property: 'createdDate', direction: 'desc' }, false).subscribe((notif) => {
        this.notificationService.getCountUnreadNotifications(this.userId).subscribe();
        this.onfilterUpdate();
      });
    });
  }

  onMarkAsReadNotification(){
    this.notificationService.getCountUnreadNotifications(this.userId).subscribe();
    this.onfilterUpdate();
  }

  loadNotificationsLazy(event){
    event["pagesToLoad"] = this.getPagesToLoad(event);

    event.pagesToLoad.forEach(pageNumber => {
      //load data of required page
      this.notificationService.fetchNotifications(this.userId, this.pageSize, pageNumber, { property: 'createdDate', direction: 'desc' }, true).subscribe((notif) => {
        this.notificationService.getCountUnreadNotifications(this.userId).subscribe();
          this.rowsDataChange = {pageNumber: pageNumber, sortBy: event.sortBy };
          this.onfilterUpdate();
      });
    });  
  }

  set rowsDataChange(value: any) {
    if (value && value.pageNumber !== -1) {
      if (this.virtualPages.findIndex(p => p === value.pageNumber) === -1) {
        this.virtualPages.push(value.pageNumber);
      }
    }
  }

  getPagesToLoad(event) {
    const firstPageNumber = Math.floor(event.first / this.pageSize);
    let lastElement = (event.first + this.pageSize);
    const pageCount = Math.ceil(lastElement / this.pageSize);
    let pagesToLoad = [];

    for (let index = 0; index < pageCount; index++) {
      const pageNumber = firstPageNumber + index;
      if (this.virtualPages.findIndex(p => p === pageNumber) === -1) {
        pagesToLoad.push(pageNumber);
      }
    }
    return pagesToLoad;
  }

  ngOnDestroy() {
    if (this.notificationsSub) {
      this.notificationsSub.unsubscribe();
    }
    if(this.countUnreadSub){
      this.countUnreadSub.unsubscribe();
    }
    this._destroying$.next();
    this._destroying$.complete();
  }

}
