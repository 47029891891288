import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';

@Component({
	selector: 'app-no-tanks-info-message',
	templateUrl: './no-tanks-info-message.component.html',
	styleUrls: ['./no-tanks-info-message.component.scss'],
})
export class NoTanksInfoMessageComponent implements OnInit {
	message: Message;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.message = {
			severity: 'info',
			summary: this.translateService.instant('Información'),
			detail: this.translateService.instant(
				'La planificación automática de la instalación impide que esta opción esté disponible.'
			),
		};
	}
}
