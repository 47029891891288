import { Component, Input, OnInit } from '@angular/core';
import { Countries } from 'src/app/constants';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Input('countryCode') countryCode: string;
  @Input('size') size: string = Countries.SIZE_XSMALL;

  constructor() { }

  ngOnInit(): void {
  }

  getTitleByCountryCode(): string {
		return Countries.flag_codes[this.countryCode.toLowerCase()];
	}
}
