import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { USE_HASH } from './constants';
import { AuthGuard } from './services/security/auth-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DisableAccountComponent } from './disable-account/disable-account.component';

const routes: Routes = [
	{
		path: 'home',
		loadChildren: () => import('./site/home/home.module').then((m) => m.HomeModule),
		data: { breadcrumb: null, privilege: 'Home.Home.Get' },
		canLoad: [MsalGuard, AuthGuard],
	},
	{
		path: 'unauthorized',
		component: UnauthorizedComponent,
	},
	{
		path: 'disable-account',
		component: DisableAccountComponent,
	},
	{
		path: 'request-for-orders',
		loadChildren: () =>
			import('./site/request-for-orders/request-for-orders.module').then(
				(m) => m.RequestForOrdersModule
			),
		data: { breadcrumb: { label: 'routes.requests.list' } },
		canLoad: [MsalGuard],
	},
	{
		path: 'levels-report',
		loadChildren: () =>
			import('./site/levels-report/levels-report.module').then((m) => m.LevelsReportModule),
		data: { breadcrumb: { label: 'routes.levelsReport.reportHistorical' } },
		canLoad: [MsalGuard],
		// TODO: real data when levels report history exist: ->  data: { breadcrumb: { label: 'levelsReport.title' } },
	},
	{
		path: 'technical-service',
		loadChildren: () =>
			import('./site/technical-service/technical-service.module').then(
				(m) => m.TechnicalServiceModule
			),
		canLoad: [MsalGuard],
		data: { breadcrumb: { label: 'routes.technicalService.list' } },
	},
	{
		path: 'frequent-questions',
		loadChildren: () =>
			import('./site/frequent-questions/frequent-questions.module').then(
				(m) => m.FrequentQuestionsModule
			),
		canLoad: [MsalGuard],
		data: {
			preload: true,
			breadcrumb: 'Preguntas frecuentes',
		},
	},
	{
		path: 'contact',
		loadChildren: () => import('./site/contact/contact.module').then((m) => m.ContactModule),
		canLoad: [MsalGuard],
		data: {
			preload: true,
			breadcrumb: 'Contáctenos',
		},
	},
	{
		path: 'patient-order',
		loadChildren: () =>
			import('./site/request-for-orders/patient-request/patient-order.module').then(
				(m) => m.PatientOrderModule
			),
		data: { breadcrumb: { label: 'routes.homecare.orderHistorical' } },
		canLoad: [MsalGuard],
	},
	{
		path: 'ccc-patient-requests',
		loadChildren: () =>
			import('./site/request-for-orders/ccc/homecare-ccc/homecare-ccc.module').then(
				(m) => m.HomecareCccModule
			),
		data: { breadcrumb: { label: 'requests.requestsListCC' } },
		canActivate: [MsalGuard],
	},
	{
		path: 'mi-patient-requests',
		loadChildren: () =>
			import('./site/request-for-orders/medical-insurance/homecare-mi/homecare-mi.module').then(
				(m) => m.HomecareMiModule
			),
		data: { breadcrumb: { label: 'requests.requestsListCC' } },
		canActivate: [MsalGuard],
	},
	{
		path: 'data-policy',
		loadChildren: () =>
			import('./site/home/data-policy/data-policy.module').then((m) => m.DataPolicyModule),
		data: { displayMenu: false, breadcrumb: { hidden: true } },
		canLoad: [MsalGuard],
	},
	{
		path: 'claims',
		loadChildren: () => import('./site/claims/claims.module').then((m) => m.ClaimsModule),
		canLoad: [MsalGuard],
		data: { breadcrumb: { label: 'routes.claims.list' } },
	},
	{
		path: 'assistance',
		loadChildren: () =>
			import('./site/techinical-assistance/techinical-assistance.module').then(
				(m) => m.TechinicalAssistanceModule
			),
		canLoad: [MsalGuard, AuthGuard],
		data: {
			breadcrumb: { label: 'technicalAssistance.title' },
			privilege: 'TechnicalAssistance.TechnicalAssistance.List',
		},
	},
	{
		path: 'forbidden',
		loadChildren: () => import('./site/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
	},
	{
		path: 'ccc-orders',
		loadChildren: () =>
			import('./site/request-for-orders/ccc/orders-ccc/orders-ccc.module').then(
				(m) => m.OrdersCccModule
			),
		data: { breadcrumb: { label: 'ordersCCC.title' } },
	},
	{
		path: 'ccc-requests',
		loadChildren: () =>
			import('./site/request-for-orders/ccc/requests-ccc/requests-ccc.module').then(
				(m) => m.RequestsCccModule
			),
		data: { breadcrumb: { label: 'requestsCCC.title' } },
	},
	{
		path: 'notification',
		loadChildren: () =>
			import('./site/notification/notification.module').then((m) => m.NotificationComponentModule),
		canActivate: [MsalGuard],
	},
	{
		path: 'ccc-user-invitation',
		loadChildren: () =>
			import('./site/ccc-user-invitation/ccc-user-invitation.module').then(
				(m) => m.CccUserInvitationModule
			),
		canActivate: [MsalGuard],
		data: { breadcrumb: { label: 'Invitación al usuario' } },
	},
	{
		path: 'ccc-claims',
		loadChildren: () =>
			import('./site/ccc-claims/ccc-claims.module').then((m) => m.CccClaimsModule),
		canActivate: [MsalGuard],
		data: { breadcrumb: { label: 'claims.cccHistorical' } },
	},
	{
		path: 'ccc-technical-service',
		loadChildren: () =>
			import('./site/ccc-technical-service/ccc-technical-service.module').then(
				(m) => m.CccTechnicalServiceModule
			),
		canActivate: [MsalGuard],
		data: { breadcrumb: { label: 'routes.technicalService.cccHistorical' } },
	},
	{
		path: 'ccc-levels-report',
		loadChildren: () =>
			import('./site/ccc-levels-report/ccc-levels-report.module').then(
				(m) => m.CccLevelsReportModule
			),
		canActivate: [MsalGuard],
		data: { breadcrumb: { label: 'routes.levelsReport.list' } },
	},
	{
		path: 'ccc-contact-data',
		loadChildren: () =>
			import('./site/ccc-claims copy/ccc-contact-data.module').then((m) => m.CccContactDataModule),
		canActivate: [MsalGuard],
		data: {
			breadcrumb: { label: 'routes.contactData.list' },
			privilege: 'ContactData.ContactDataCCC.List',
		},
	},
	{
		path: 'ccc-reports',
		loadChildren: () =>
			import('./site/ccc-reports/ccc-reports.module').then((m) => m.CccReportsModule),
		data: { breadcrumb: { label: 'reports.title' } },
	},
	{
		path: 'update-profile',
		loadChildren: () =>
			import('./site/update-profile/update-profile.module').then((m) => m.UpdateProfileModule),
		canActivate: [MsalGuard],
		data: {
			breadcrumb: { label: 'updatePadron.route' },
			privilege: 'ContactData.ContactData.Create',
		},
	},

	{
		path: 'sales-request',
		loadChildren: () =>
			import('./site/sales-request/sales-request.module').then((m) => m.SalesRequestModule),
		canActivate: [MsalGuard],
		data: {
			breadcrumb: { label: 'routes.salesRequest.edit' },
		},
	},
	{
		path: 'dashboard-medical-insurance',
		loadChildren: () =>
			import('./site/dashboard-medical-insurance/dashboard-medical-insurance.module').then(
				(m) => m.DashboardMedicalInsuranceModule
			),
		canActivate: [MsalGuard],
	},
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: '**', redirectTo: 'home' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: USE_HASH,
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
