import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { PrivilegeService, SortBy } from 'factory-ng';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MenuItem } from 'primeng/api';
import { Subject, Subscription, filter, take, takeUntil } from 'rxjs';
import { HEADER_MAX_USERACCOUNTS, SessionStorageItems } from 'src/app/constants';
import Role, { RoleNames } from 'src/app/models/security/role.model';
import UserAccount from 'src/app/models/security/user-account.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { SessionStorageService } from 'src/app/services/generic/storage.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserAccountService } from 'src/app/services/security/user-account.service';
import { UserInfoService } from 'src/app/services/security/user-info.service';
import { UserRoleService } from 'src/app/services/security/user.role.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	private readonly _destroying$ = new Subject<void>();
	countNotifications = 0;

	private countUnreadSub: Subscription;

	userAccount: UserAccount;

	userAccountsItems: MenuItem[];
	headerMenuItems: MenuItem[];

	userRole: Role | null;

	userInfo: UserInfo;

	roleNames = RoleNames;

	displayNotif: boolean = false;
	hasPrivilege: boolean = false;

	showSelectAccountPopUp: boolean = false;
	countUserAccounts: number;
	maxUserAccountHeader: number = HEADER_MAX_USERACCOUNTS;

	constructor(
		private authService: MsalService,
		private sessionStorage: SessionStorageService,
		private userAccountService: UserAccountService,
		private translateService: TranslateService,
		private userRoleService: UserRoleService,
		private userInfoService: UserInfoService,
		private loadingService: NgxUiLoaderService,
		private notificationService: NotificationService,
		private router: Router,
		private privilegeService: PrivilegeService
	) {}

	ngOnInit(): void {
		//Wait until the account is loaded
		this.userAccountService.loadingUserAccounts$
			.pipe(
				filter((l) => !l), //Wait until loading is false
				takeUntil(this._destroying$),
				take(1)
			)
			.subscribe(() => {
				this.loadingService.start();
				this.userAccountService.countUserAccounts$
					.pipe(takeUntil(this._destroying$))
					.subscribe((result) => {
						this.countUserAccounts = result;
					});
				this.userAccountService.selectedUserAccount$
					.pipe(takeUntil(this._destroying$))
					.subscribe((ua) => {
						this.userAccount = ua;
						if (ua && ua.userId) {
							this.notificationService.getCountUnreadNotifications(ua?.userId).subscribe();
						}
					});

				this.userRoleService.userRoles$.pipe(takeUntil(this._destroying$)).subscribe((userRole) => {
					if (userRole) {
						this.userRole = userRole[0];
					}

					this.userInfoService.userInfo$.pipe(takeUntil(this._destroying$)).subscribe((user) => {
						this.userInfo = user;
					});
				});

				this.translateService.get('dummy').subscribe((_) => {
					this.initializeHeader();
				});

				this.countUnreadSub = this.notificationService.countUnread.subscribe((count) => {
					this.countNotifications = count;
				});

				this.loadingService.stop();
			});
	}

	isCCC() {
		return (
			this.userRole?.name == this.roleNames.AR_CCC_OPERATOR ||
			this.userRole?.name == this.roleNames.AR_CCC_RESPONSIBLE
		);
	}

	initializeHeader(): void {
		const defaultSortBy: SortBy = {
			direction: 'asc',
			property: 'id',
		};
		this.userAccountService
			.getUserAccountsByUserId(this.userInfo.id, this.maxUserAccountHeader, 0, defaultSortBy)
			.subscribe({
				next: (response) => {
					this.userAccountsItems = response.body.map((userAccount) => {
						return {
							label:
								this.userRole?.name === RoleNames.AR_CUSTOMER ||
								this.userRole?.name === RoleNames.AR_CCC_RESPONSIBLE ||
								this.userRole?.name === RoleNames.AR_CCC_OPERATOR
									? userAccount.account.companyName + ' | ' + userAccount.account.erpId
									: userAccount.account.companyName + ' | ' + userAccount.account.erpId,
							command: () => {
								this.userAccount = userAccount;
								this.setAccountSelected(userAccount);
							},
						};
					});
				},

				error: (error) => {},
			});
		this.headerMenuItems = [
			{
				label: this.translateService.instant('header.changePadron'),
				command: () => {
					if (this.privilegeService.isPrivilegeValid('ContactData.ContactData.Create')) {
						this.router.navigate(['update-profile']);
					}
				},
			},
			{
				label: this.translateService.instant('header.logout'),
				command: () => {
					this.logout();
				},
			},
		];
	}

	ngOnDestroy(): void {
		if (this.countUnreadSub) {
			this.countUnreadSub.unsubscribe();
		}
		this._destroying$.next();
		this._destroying$.complete();
	}

	logout() {
		const logoutHint = this.sessionStorage.get(SessionStorageItems.ID_TOKEN);
		this.authService.logoutRedirect({ idTokenHint: logoutHint });
	}

	viewWillEnter() {
		this.userAccountService.selectedUserAccount$
			.pipe(takeUntil(this._destroying$))
			.subscribe((ua) => {
				if (ua && ua.userId) {
					this.notificationService.getCountUnreadNotifications(ua?.userId).subscribe();
				}
			});
	}

	goToNotification() {
		this.displayNotif = !this.displayNotif;
	}

	hasPrivileges() {
		return this.privilegeService.isPrivilegeValid('Notifications.Notification.List');
	}

	onClickSelectAccount() {
		this.showSelectAccountPopUp = true;
	}

	handleSelectAccountCancelled() {
		this.showSelectAccountPopUp = false;
	}

	handleInvitationSaved() {}

	setAccountSelected(userAccount: UserAccount) {
		this.userAccountService.setAccountSelected(userAccount);
	}
}
