import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { CellGridPipe, GridModule, LoadingModule } from 'factory-ng';
import { ImageNotFoundDirective } from './directives/image-not-found.directive';
import { SelectedGridComponent } from './selected-grid/selected-grid.component';
import { TableModule } from 'primeng/table';
import { NoTanksInfoMessageComponent } from './no-tanks-info-message/no-tanks-info-message.component';
import { MessagesModule } from 'primeng/messages';
import { TankLevelComponent } from './tank-level/tank-level.component';
import { FlagComponent } from './flag/flag.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
	declarations: [
		ImageNotFoundDirective,
		SelectedGridComponent,
		NoTanksInfoMessageComponent,
		TankLevelComponent,
		FlagComponent,
	],
	imports: [
		CommonModule,
		ButtonModule,
		TranslateModule,
		LoadingModule,
		TableModule,
		MessagesModule,
		TooltipModule,
		GridModule,
	],
	exports: [
		ImageNotFoundDirective,
		SelectedGridComponent,
		NoTanksInfoMessageComponent,
		TankLevelComponent,
		FlagComponent,
	],
})
export class GenericModule {}
