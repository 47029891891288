import {
  Directive,
  ElementRef,
  OnChanges,
  Input,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
  selector: '[menuConfiguration]',
})

/**
 * Directive to change the main behavior of the PrimeNG Menu used on the aplication.
 */
export class MenuConfiguration implements OnChanges {
  @Input() menuItemsLength: number = 0;

  currentRoute: string;

  iterated = false;

  constructor(private el: ElementRef, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // On every router navigation check the menu
        this.checkMenu();
      }
    });
  }

  ngOnChanges() {
    // Check when the menu is loaded and inserted in the PrimeNG component.
    (this.el.nativeElement as Element).firstElementChild.addEventListener(
      'DOMNodeInserted',
      () => {
        this.checkMenu();
      }
    );
  }

  /**
   * Check every PrimeNG menu items to set the main menu items as active when the correct route is active
   */
  checkMenu(): void {
    const menuElements = (this.el.nativeElement as Element).firstElementChild
      .children;
    Array.from(menuElements).forEach((menuItem, index) => {
      if (
        this.menuItemsLength &&
        Array.from(menuElements).length === this.menuItemsLength
      ) {
        setTimeout(() => {
          if (Array.from(menuItem.children).length > 1) {
            this.checkIfIsFatherActive(
              menuItem.lastElementChild.firstElementChild.firstElementChild
                .firstElementChild
            );
          }
        });
      }
    });
  }

  checkIfIsFatherActive(ulItem: Element): void {
    if (
      Array.from(ulItem.children).some((menuItem) =>
        menuItem.firstElementChild.classList.contains('p-menuitem-link-active')
      )
    ) {
      ulItem.parentElement.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.classList.add(
        'p-menuitem-link-active'
      );
    } else {
      ulItem.parentElement.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.classList.remove(
        'p-menuitem-link-active'
      );
    }
  }
}
