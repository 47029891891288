import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil, Observable, of, switchMap } from 'rxjs';
import { MenuConfigurationItem } from 'src/app/layout/menu/utils/menu-configuration';
import Country from 'src/app/models/generic/countries.model';
import UserAccount from 'src/app/models/security/user-account.model';
import { CountriesService } from '../generic/countries.service';
import { UserAccountService } from '../security/user-account.service';

@Injectable({
	providedIn: 'root',
})
export class MenuConfigurationService implements OnDestroy {
	private readonly _destroying$ = new Subject<void>();

	menuArr: MenuConfigurationItem[] = [
		{
			permissionsTag: 'Home.Home.GetMenu',
			label: 'routes.home',
			icon: 'linder-icon home-icon',
			routerLink: '/home',
			routerLinkActiveOptions: { exact: true },
			styleClass: 'item-selected single-item',
		},
		{
			permissionsTag: 'Users.UserInvitationCCC.List',
			label: 'userInvitation.menu',
			icon: 'linder-icon user-icon',
			routerLink: '/ccc-user-invitation',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
		{
			permissionsTag: 'Requests.RequestsCCC.List',
			label: 'requestForOrders.title',
			icon: 'linder-icon request-order-icon',
			styleClass: 'item-selected',
			type: ['CCC'],
			items: [
				{
					permissionsTag: 'Requests.RequestsCCC.List',
					label: 'ordersCCC.title',
					routerLink: '/ccc-orders',
					routerLinkActiveOptions: { exact: true },
					type: ['CCC'],
				},
				{
					permissionsTag: 'Requests.RequestsCCC.List',
					id: 'specialGases',
					label: 'requestsCCC.title',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/ccc-requests',
					type: ['CCC'],
				},
			],
		},
		{
			permissionsTag: 'Requests.DashboardMI.Get',
			label: 'dashboardMedicalInsurance.menuTitle',
			icon: 'linder-icon dashboard-icon',
			routerLink: '/dashboard-medical-insurance',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['OS'],
		},
		{
			permissionsTag: 'Requests.PatientRequestsMI.List',
			label: 'requests.homecare.homecare',
			icon: 'linder-icon homecare-icon',
			routerLink: '/mi-patient-requests',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['OS'],
		},
		{
			permissionsTag: 'Requests.PatientRequestsCCC.List',
			label: 'requests.homecare.homecare',
			icon: 'linder-icon homecare-icon',
			routerLink: '/ccc-patient-requests',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
		{
			permissionsTag: 'Requests.Request.List',
			id: 'packaged',
			label: 'routes.requests.igRequest.menu',
			icon: 'linder-icon request-order-icon',
			styleClass: 'item-selected',
			items: [
				{
					permissionsTag: 'Requests.Request.List',
					id: '',
					label: 'routes.requests.list',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/request-for-orders',
				},
				{
					permissionsTag: 'Requests.PackagedRequest.Create',
					label: 'packagedProducts.title',
					routerLink: '/request-for-orders/packaged-products/new',
					routerLinkActiveOptions: { exact: true },
				},
				{
					permissionsTag: 'Requests.SpecialGasesRequest.Create',
					id: 'specialGases',
					label: 'specialGases.title',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/request-for-orders/special-gases/new',
				},
				{
					permissionsTag: 'Requests.MaterialRequest.Create',
					id: 'materials',
					label: 'requests.materials.materials',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/request-for-orders/materials/new',
				},
				{
					permissionsTag: 'Requests.BulkRequest.Create',
					id: 'bulks',
					label: 'bulks.title',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/request-for-orders/bulks/new',
				},
				{
					permissionsTag: 'Requests.PackagedRequest.Create',
					id: 'sales-request',
					label: 'salesRequest.title',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/sales-request',
				},
			],
		},
		{
			permissionsTag: 'Requests.PatientRequest.List',
			id: 'packaged',
			label: 'routes.homecare.menu',
			icon: 'linder-icon request-order-icon',
			styleClass: 'item-selected',
			items: [
				{
					permissionsTag: 'Requests.PatientRequest.List',
					id: '',
					label: 'routes.requests.list',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/patient-order',
				},
				{
					permissionsTag: 'Requests.PatientRequest.Create',
					id: 'homecare',
					label: 'routes.requests.patient.new',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/patient-order/new',
				},
			],
		},
		{
			permissionsTag: 'TechnicalAssistance.TechnicalAssistance.List',
			label: 'technicalAssistance.title',
			icon: 'linder-icon technical-assistance-icon',
			routerLink: '/assistance',
			routerLinkActiveOptions: { exact: true },
			styleClass: 'item-selected single-item',
			type: ['OS', 'PAT'],
		},
		{
			permissionsTag: 'LevelReports.LevelReport.List',
			label: 'levelsReport.title',
			icon: 'linder-icon level-report-icon',
			styleClass: 'item-selected',
			items: [
				{
					permissionsTag: 'LevelReports.LevelReport.List',
					label: 'levelsReport.reportHistorical',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/levels-report',
				},
				{
					permissionsTag: 'LevelReports.LevelReport.Create',
					label: 'levelsReport.reportRequest',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/levels-report/new',
				},
			],
		},
		{
			permissionsTag: 'TechnicalServices.TechnicalService.List',
			label: 'technicalService.title',
			icon: 'linder-icon technical-service-icon',
			styleClass: 'item-selected',
			items: [
				{
					permissionsTag: 'TechnicalServices.TechnicalService.List',
					label: 'technicalService.serviceHistorical',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/technical-service',
				},
				{
					permissionsTag: 'TechnicalServices.TechnicalService.Create',
					label: 'technicalService.serviceRequets',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/technical-service/new',
				},
			],
		},
		{
			permissionsTag: 'Claims.Claim.List',
			label: 'suggestions.title',
			icon: 'linder-icon voice-icon',
			styleClass: 'item-selected',
			type: ['OS', 'CUST', 'PAT'],
			items: [
				{
					permissionsTag: 'Claims.Claim.List',
					label: 'suggestions.suggestionsHistorical',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/claims',
					type: ['OS', 'CUST', 'PAT'],
				},
				{
					permissionsTag: 'Claims.Claim.Create',
					label: 'suggestions.suggestionsRequest',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/claims/new',
					type: ['OS', 'CUST', 'PAT'],
				},
			],
		},
		{
			permissionsTag: 'Reports.BranchOfficeLimit.List',
			label: 'reports.title',
			icon: 'linder-icon reports-icon',
			styleClass: 'item-selected',
			type: ['CCC'],
			items: [
				{
					permissionsTag: 'Reports.BranchOfficeLimit.List',
					label: 'reports.branchOfficeLimit.title',
					routerLinkActiveOptions: { exact: true },
					routerLink: '/ccc-reports/branchOffice',
					type: ['CCC'],
				},
				{
					permissionsTag: 'Reports.BranchOfficeLimit.List',
					label: 'reports.operationIndicators.title',
					type: ['CCC'],
				},
			],
		},
		{
			id: 'contact',
			permissionsTag: 'Contact.Chat.Get',
			label: 'contact.title',
			icon: 'linder-icon contact-icon',
			styleClass: 'item-selected',
			type: ['OS', 'CUST', 'PAT'],
			items: [
				{
					// TODO: Change for the right whatsapp permissions
					permissionsTag: 'Contact.Email.Get',
					label: 'contact.whatsapp',
					styleClass: 'mb-3',
					type: ['OS', 'CUST', 'PAT'],
				},
				/*
        {
          permissionsTag: 'Contact.Chat.Get',
          label: 'contact.contactUs',
        },
        {
          permissionsTag: 'Contact.Call.Get',
          label: 'contact.representative',
        },
        */
			],
		},
		{
			permissionsTag: 'LevelsReport.LevelsReportCCC.List',
			label: 'levelsReport.title',
			icon: 'linder-icon level-report-icon',
			routerLink: '/ccc-levels-report',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
		{
			permissionsTag: 'Claims.ClaimCCC.List',
			label: 'suggestions.title',
			icon: 'linder-icon voice-icon',
			routerLink: '/ccc-claims',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
		{
			permissionsTag: 'TechnicalServices.TechnicalServiceCCC.List',
			label: 'technicalService.title',
			icon: 'linder-icon technical-service-icon',
			routerLink: '/ccc-technical-service',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
		{
			permissionsTag: 'TechnicalServices.TechnicalServiceCCC.List',
			label: 'updatePadron.menu',
			icon: 'linder-icon personal-data-icon',
			routerLink: '/ccc-contact-data',
			routerLinkActiveOptions: { exact: false },
			styleClass: 'item-selected single-item',
			type: ['CCC'],
		},
	];
	constructor(
		private countriesService: CountriesService,
		private userAccountService: UserAccountService
	) {}

	public GetMenuConfiguration(): Observable<MenuConfigurationItem[]> {
		return this.userAccountService.selectedUserAccount$.pipe(
			takeUntil(this._destroying$),
			switchMap((ua) => {
				var userAccount: UserAccount = ua;
				if (userAccount != null) {
					return this.countriesService.getCountries(userAccount.account?.countryCode);
				}
				return of(null);
			}),
			switchMap((cs) => {
				if (cs && cs.length > 0) {
					this.menuArr.find((x) => x.id === 'contact').items.push(...this.GetContactMenu(cs[0]));
				}
				return of(this.menuArr);
			})
		);
	}
	private GetContactMenu(country: Country): MenuConfigurationItem[] {
		var contactItems = [];
		if (country.phoneCountryCCC) {
			contactItems.push({
				permissionsTag: 'Contact.Chat.Get',
				label: country.phoneCountryCCC,
				styleClass: 'contactInfo',
				type: ['OS', 'CUST', 'PAT'],
			});
		}
		if (country.emailCountryCCC) {
			contactItems.push({
				permissionsTag: 'Contact.Chat.Get',
				label: country.emailCountryCCC,
				styleClass: 'contactInfo',
				type: ['OS', 'CUST', 'PAT'],
			});
		}
		if (country.web) {
			contactItems.push({
				permissionsTag: 'Contact.Chat.Get',
				label: country.web,
				styleClass: 'contactInfo',
				type: ['OS', 'CUST', 'PAT'],
			});
		}
		if (country.schedule) {
			contactItems.push({
				permissionsTag: 'Contact.Chat.Get',
				label: country.schedule.split('|')[0],
				styleClass: 'contactInfo',
				type: ['OS', 'CUST', 'PAT'],
			});

			if (country.schedule.indexOf('|') > -1) {
				contactItems.push({
					permissionsTag: 'Contact.Chat.Get',
					label: country.schedule.split('|')[1],
					styleClass: 'contactInfo splitted',
					type: ['OS', 'CUST', 'PAT'],
				});
			}
		}
		if (country.additionalReference) {
			var references = country.additionalReference.split('|');
			references.forEach((element) => {
				contactItems.push({
					permissionsTag: 'Contact.Chat.Get',
					label: element,
					styleClass: 'contactInfo',
					type: ['OS', 'CUST', 'PAT'],
				});
			});
		}
		return contactItems;
	}
	ngOnDestroy(): void {
		this._destroying$.next();
		this._destroying$.complete();
	}
}
