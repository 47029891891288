import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { CellGridPipe, FactoryNgConfig, GridColumn, PrivilegeService, RowAction, SortBy } from 'factory-ng';

@Component({
  selector: 'app-selected-grid',
  templateUrl: './selected-grid.component.html',
  styleUrls: ['./selected-grid.component.scss']
})
export class SelectedGridComponent implements OnInit {

  @Input() cellTooltipPosition: string = "right";
  @Input() defaultSortBy: SortBy;
  @Input() headerHeight: string = '70px';
  @Input() loading: boolean = true;
  @Input() pageSize: number;
  @Input() rows = [];
  @Input() storageName: string;

  /**Defines whether sorting configuration should be stored in cache or not */
  @Input() useCacheSorting: boolean = true;
  @Input() virtualRowHeight: number;

  private _columns: GridColumn[] = [];
  get columns(): GridColumn[] {
    return this._columns;
  }
  @Input() set columns(cs: GridColumn[]) {
    this._columns = cs;
    this.colsUserVisible = cs?.filter(c => c.userVisible);
    this.colsGridVisible = this.colsUserVisible;
  }

  @Input() set rowsDataChange(value: any) {
    if (value && value.pageNumber !== -1) {
      const first = this.pageSize * value.pageNumber;
      this.virtualRows.splice(first, this.pageSize, ...this.rows);
      this.virtualRows = [...this.virtualRows];

      if (this.virtualPages.findIndex(p => p === value.pageNumber) === -1) {
        this.virtualPages.push(value.pageNumber);
      }
    }
  }
  
  private _totalCount: number;
  get totalCount(): number {
    return this._totalCount;
  }
  @Input() set totalCount(v: number) {
    this._totalCount = v;
    this.reloadGrid();
  }

  @Output('onLazyLoad') onLazyLoadEvent = new EventEmitter<any>();
  @Output('onSelectedRow') onSelectedRow = new EventEmitter<any>();

  /**Array with the current loaded rows, and undefined on unloaded empty spaces. When the page n
   * is lazy-loaded, the undefined elements from (n*pageSize) to (n*pageSize + pageSize) get replaced
   * with the actual rows. 
  */
  virtualRows: any[] = [];

  /**Array with the current loaded page numbers.*/
  virtualPages: number[] = [];

  colsUserVisible: GridColumn[] = [];
  colsGridVisible: GridColumn[] = [];
  rowSelected: any;
  visible: boolean = true;
  previousSortBy: SortBy = new SortBy(null, null);
  selectedRow: any;

  /**Total height of the table, sum of the header and the scrollable content */
  get tableHeight(): string {
    return `calc(${this.headerHeight} + ${this.virtualRows?.length * this.virtualRowHeight}px)`;
  }

  constructor(
    private cellGridPipe: CellGridPipe,
    private config: FactoryNgConfig,
    private privilegeService: PrivilegeService,    
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  

  loadLazy(event: LazyLoadEvent) {
    event["sortBy"] = this.getSortBy(event);
    event["pagesToLoad"] = this.getPagesToLoad(event);

    this.onLazyLoadEvent.emit(event);
  }

  private getSortBy(event: LazyLoadEvent) {
    let sortBy = null;
    if (event.sortField) {
      sortBy = new SortBy(event.sortField, event.sortOrder === 1 ? 'asc' : 'desc');
    }
    return sortBy;
  }

  /**Returns a list with the page numbers that need to be loaded*/
  getPagesToLoad(event): number[] {
    //If sortBy has changed, clear all the rows and load the first page
    if (event.sortBy && (
        this.previousSortBy.property != event.sortBy.property
        || this.previousSortBy.direction != event.sortBy.direction
    )) {
      this.previousSortBy.property = event.sortBy.property;
      this.previousSortBy.direction = event.sortBy.direction;
      this.resetVirtualProps();
    }
    
    let eventPageNumber = Math.ceil(event.first / this.pageSize);
    if (this.virtualPages.findIndex(p => p === eventPageNumber) > -1) {
      //The page has already been loaded
      return [];
    }
    //Return the page number that needs to be loaded
    return [eventPageNumber];
  }

  getColumnWidthStyle(width: string): string {
    if (!width) {
      return '';
    }
    //Width sets the minimum width of the text, and flex-grow allows it to grow up to its parent <td>
    //container's width, that can be larger when the grid is small and the columns have space to grow
    return `width: ${width}; flex-grow: 1;`;
  }

  getRowHeightStyle() {
    return 'height: ' + this.virtualRowHeight + 'px;';
  }

  disableToolTip(tooltipContent) {
    const contentFormated = this.cellGridPipe.transform(tooltipContent);
    return contentFormated === '' || contentFormated === null;
  }

  navigateCellLink(column: GridColumn, row: any) {
    if(column.link) {
      this.router.navigate(column.link(row));
    }
  }

  getTranslation(option: string) {
    return this.config.getTranslation(option);
  }


  private reloadGrid() {
    if (this.totalCount >= 0) {
      this.resetVirtualProps();

      this.visible = false;
      setTimeout(() => this.visible = true, 0);
    }
  }
  

  private resetVirtualProps() {
    this.virtualRows = Array.from({ length: this.totalCount });
    this.virtualPages = [];
  }

  onRowSelect(event)
  {
    this.onSelectedRow.emit(event);
  }
}
