import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';

@Component({
	selector: 'app-more-than-one-tank-message',
	templateUrl: './more-than-one-tank-message.component.html',
	styleUrls: ['./more-than-one-tank-message.component.scss'],
})
export class MoreThanOneTankMessageComponent implements OnInit {
	message: Message;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.message = {
			severity: 'info',
			summary: this.translateService.instant('Información'),
			detail: this.translateService.instant(
				'Debe informar el nivel de todos los tanques de la instalación seleccionada.'
			),
		};
	}
}
