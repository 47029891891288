export default class Role {
	id?: number | null;
	name?: RoleNames | null;
	description?: string | null;
	applicationCode?: string | null;
	accountId?: number | null;
}

export enum RoleNames {
	AR_CCC_RESPONSIBLE = 'AR-CCC_RESPONSIBLE',
	AR_CCC_OPERATOR = 'AR-CCC_OPERATOR',
	AR_CUSTOMER = 'AR-CUSTOMER',
	AR_PATIENT = 'AR-PATIENT',
	AR_MEDICAL_INSURANCE = 'AR-MEDICAL_INSURANCE',
	AR_WAREHOUSE = 'AR-WAREHOUSE',
}

export const CCC_ROLE_NAMES: string[] = [
	RoleNames.AR_CCC_OPERATOR,
	RoleNames.AR_CCC_RESPONSIBLE,
	RoleNames.AR_CUSTOMER,
];
