import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { TranslateModule } from '@ngx-translate/core';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationComponent } from './notification.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { GenericModule } from 'src/app/generic/generic.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    DividerModule,
    TooltipModule,
    RouterModule,
    VirtualScrollerModule, 
    GenericModule
  ],
  declarations: [
    NotificationComponent,
    NotificationItemComponent
  ],
  exports:[
    NotificationComponent,
    NotificationItemComponent
  ]
})
export class NotificationComponentModule {}
