<div [ngClass]="{ 'active-menu': !menuToggler }" class="layout-menu">
    <div [ngClass]="{ 'active-menu': !menuToggler }" class="menu">
        <div [ngClass]="{ 'active-menu': !menuToggler }" class="logo">
            <div class="logo-container">
                <a routerLink="/home">
                    <div class="logo-image">
                        <img [src]="imagePath + '/layout/header/logo.png'" alt="">
                    </div>
                    <div #logoText class="logo-text">
                        <span>My Linde</span>
                    </div>
                </a>
            </div>
        </div>
        <div [ngClass]="{ 'active-menu': !menuToggler }" (click)="toggleMenu()" class="menu-burger">
            <a href="#" (click)="$event.preventDefault();"> <img [src]="imagePath + '/icons/burguer.svg'" alt="" appImageNotFound> </a>
        </div>
        <div *ngIf="(!resetMenu && menuToggler) || isMobileMode()" [ngClass]="{ 'active-menu': !menuToggler }" class="menu-items flex flex-column">
            <p-panelMenu menuConfiguration [menuItemsLength]="menuItemsLength" menuConfiguration [model]="menu"></p-panelMenu>
        </div>
        <!-- This menu is for visual purpuse to avoid flickering of the original when resetting its state -->
        <div *ngIf="!menuToggler  && !isMobileMode()" [ngClass]="{ 'active-menu': !menuToggler }" class="menu-items flex flex-column">
            <p-panelMenu menuConfiguration [menuItemsLength]="menuItemsLength" [model]="menu"></p-panelMenu>
        </div>
    </div>
</div>
