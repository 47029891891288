import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	BreadcrumbsModule,
	GridModule,
	NavigateBackModule,
	PrivilegeModule,
	SidebarModule,
} from 'factory-ng';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ImageModule } from 'primeng/image';

import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { MenuComponent } from '../menu/menu.component';
import { MainComponent } from './main.component';
import { createTranslateLoader } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { UserAccountService } from 'src/app/services/security/user-account.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuConfiguration } from '../menu/utils/menu-configuration.directive';
import { BadgeModule } from 'primeng/badge';
import { NotificationComponentModule } from 'src/app/site/notification/notification.module';
import { SidebarModule as SidebarModulePrimeNG } from 'primeng/sidebar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { GenericModule } from 'src/app/generic/generic.module';
import { SelectAccountComponent } from '../header/select-account/select-account.component';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';


const PRIMENG = [
  BreadcrumbModule,
  MenubarModule,
  OverlayPanelModule,
  ImageModule,
  InputTextModule,
  SplitButtonModule,
  ButtonModule,
  SidebarModulePrimeNG,
  VirtualScrollerModule,
  DialogModule,
  TableModule,
  ReactiveFormsModule,
  DropdownModule
];
const FactoryNG = [BreadcrumbsModule, NavigateBackModule, SidebarModule, PanelMenuModule];

@NgModule({
  declarations: [
    MainComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    MenuConfiguration,
    SelectAccountComponent
  ],
  imports: [
    CommonModule,
    ...PRIMENG,
    ...FactoryNG,
    TranslateModule,
    BadgeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    PrivilegeModule,
    NotificationComponentModule,
    GenericModule,
    GridModule
  ],
  exports: [MainComponent],
  providers: [UserAccountService],
})
export class MainModule {}
