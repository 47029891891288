export default class ValueList {
	id?: number | null;
	value?: string | null;
	description?: string | null;
	entityId?: number | null;
	abbreviation?: string;
	backgroundColor?: string;
}

export enum ValueListReferences {
	REQUEST_TYPE = 'RequestType',
	CLAIM_TYPE = 'ClaimType',
}

export enum ValueListValues {
	MATERIALS = 'Materials',
	PACKAGED = 'Packaged',
	BULK = 'Bulk',
	SPECIAL_GASES = 'SpecialGases',
}

export class ValueListFilterValue {
	public name: string;

	constructor(public id: any, public description: string) {}
}
