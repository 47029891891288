import AuditInfo from '../generic/audit-info.model';
import VersionInfo from '../generic/version-info.model';
import Account from './account.model';

export default class UserAccount {
	constructor(useraccount?: UserAccount) {
		Object.assign(this, useraccount);
	}

	id?: number;
	userId: number;
	accountId: number;
	invitedByUserId?: number;
	isCustomerMainUser: boolean;
	isDefault: boolean;
	lastAccessDate?: Date;
	termsConditions: boolean;
	account?: Account;
	accountCompanyName?: string;
	deliveryLocationAddress: string;
	deliveryLocationId: string;
	customerType?: string;
	isEnabled?: string;
	document?: string;
	public auditAttributes: AuditInfo;
	public versionAttributes: VersionInfo;
}
