<h2 class="views-title">
	{{ 'disableAccount.title' | translate }}
</h2>

<div>
	<p class="input-text">
		{{ 'disableAccount.sorry' | translate }}
	</p>
	<p class="input-text">
		{{ 'disableAccount.explanation' | translate }}
	</p>
</div>
