<p-dialog
	contentStyleClass="pt-0"
	[closable]="true"
	[modal]="true"
	[style]="{ width: '60vw', height: 'auto' }"
	[(visible)]="visible"
    (onHide)="onCancelClick()"
    (onShow)="onShow()"
   
>
    <ng-template pTemplate="header">
        <div class="flex justify-content-between">
            <span class="text-xl font-bold">{{ 'selectAccount.title' | translate }}</span>
        </div>
    </ng-template>
    <div class="mb-3">
        <div class="flex-grow-1 requests-filters">
            <form [formGroup]="formInput">
               
                <div class="grid">
                 <div class="col-3">               
                        <p-dropdown
                        *ngIf="showCountryOptions"
                        formControlName="countryCode"
                        optionLabel="name"
                        optionValue="code"
                        [options]="countryOptions"
                        class="w-full"
                        [style]="{ width: '100%'}"
                        (onChange)="onCountryChange($event)"                   
                    ></p-dropdown> 
                 </div>
                 <div class="col-8">
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input
                        class="w-6 p-inputtext"
                        formControlName="searchQuery"
                        pInputText
                        type="text"
                        placeholder="{{'selectAccount.search' | translate}}"
                        [maxLength]="300"
                        (ionBlur)="onAddText()" 
                        (keyup.enter)="onAddText()"
                        class="w-full"
                    />
                    </span>
                </div>
                <div class="col-1 text-right align-self-center">
                    <button
                            label="{{ 'Search' | translate }}"
                            pButton
                            type="button"
                            (click)="search()"
                    ></button>
                    
                </div> 
                  
                </div>
               
            </form>
        </div>
    </div>

    <div class="flex">
        <div class="p-card flex-grow-1 px-3 pb-3 pt-1 mb-5">
           <app-selected-grid
            cellTooltipPosition="left"
			headerHeight="70px"
			[columns]="columns"
			[defaultSortBy]="{ property: 'id', direction: 'desc' }"
			[loading]="loading"
			[pageSize]="pageSize"
			[rows]="rows"
			[rowsDataChange]="rowsDataChange"
			[storageName]="storageName"
			[totalCount]="totalCount"
			[useCacheSorting]="false"
			[virtualRowHeight]="49"
			(onLazyLoad)="loadLazy($event)"
            (onSelectedRow)="onAccountSelected($event)"
            ></app-selected-grid>
        </div>
    </div>
</p-dialog>