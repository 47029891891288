export class DateUtils {
	public static toDateString(date_Object: Date): string {
		// get the year, month, date, hours, and minutes seprately and append to the string.
		let date_String: string =
			date_Object.getFullYear() + '/' + (date_Object.getMonth() + 1) + '/' + date_Object.getDate();
		return date_String;
	}

	public static toDateTimeStringId(date: Date): string {
		let pad2 = function (n) {
			return n < 10 ? '0' + n : n;
		};

		return (
			date.getFullYear().toString() +
			pad2(date.getMonth() + 1) +
			pad2(date.getDate()) +
			pad2(date.getHours()) +
			pad2(date.getMinutes()) +
			pad2(date.getSeconds())
		);
	}
}
