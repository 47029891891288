import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  imagePath: string = environment.imagesUrl;

  currentYear: number = new Date().getFullYear();

  constructor(
    private router: Router
  ) { }

  navigateToDataPolicy(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/data-policy']));
    window.open(url, '_blank');
  }
}
