import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import Entity from "src/app/models/generic/entity.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EntityService {
    
  constructor(
    private httpClient: HttpClient  ) {}


  getEntityByName(name: string): Observable<Entity> {
    let params = new HttpParams().append('name', name);

    return this.httpClient.get<Entity[]>(`${environment.businessAPI}/entitys`, { params: params })
        .pipe(map(es => es?.length ? es[0] : null));
  }

}