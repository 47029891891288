export default class AccountType {
	code?: string;
	name?: string;
}

export enum AccountTypes {
    CUSTOMER = '1',
    PATIENT = '2',
    MEDICAL_INSURANCE = '3',
    CCC = '4'
}
