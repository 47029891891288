import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  get(key: string){
    return sessionStorage.getItem(key);
  }

  set(key: string, value: string){
    sessionStorage.setItem(key, value);
  }

  clean(key: string) {
    sessionStorage.removeItem(key);
  }

  cleanAll(){
    sessionStorage.clear();
  }

}

