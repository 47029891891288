import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap } from 'rxjs';
import Role, { RoleNames } from 'src/app/models/security/role.model';
import UserAccount from 'src/app/models/security/user-account.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private readonly _userRoles$ = new BehaviorSubject<Role[]>(undefined);
  userRoles$: Observable<Role[]> = this._userRoles$.asObservable();

  private readonly _loadingUserRoles$ = new BehaviorSubject<boolean>(true);
  loadingUserRoles$: Observable<boolean> =
    this._loadingUserRoles$.asObservable();

  constructor(private httpClient: HttpClient) {}

  getLoggedInUserRoles(userId: number): Observable<UserAccount[]> {
    let params = new HttpParams().append('userId', userId);
    return this.httpClient.get<UserAccount[]>(
      `${environment.platformAPI}/.me/roles`,
      { params: params }
    );
  }

  initializeUserRoles(ui: UserInfo) {
    this._userRoles$.next([]);
    this._loadingUserRoles$.next(true);
    if (ui) {
      this.getLoggedInUserRoles(ui.id).subscribe({
        next: (rs) => {
          this._userRoles$.next(rs ?? []);
          this._loadingUserRoles$.next(false);
        },
        error: (error) => {
          this._userRoles$.next([]);
          this._loadingUserRoles$.next(false);
        },
      });
    }
  }

  checkRolesPermissions(roleNames: RoleNames[]): Observable<boolean> {
    return this.userRoles$.pipe(
      map((userRoles) => {
        return userRoles.some((userRolesData) =>
          roleNames.some((roleName) => roleName === userRolesData.name)
        );
      })
    );
  }
}
