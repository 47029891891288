<div class="p-card p-3 mb-4" [class]="!notification.dismissedDate ? 'background-active' : 'background-disabled'">
    <div lines="none" button detail="false" (click)="markAsRead()">
        <div class="flex align-items-center w-full justify-content-between">
            <p [class]="!notification.dismissedDate ? 'Body-M-Regular' : 'Body-M-Regular-medium'">
                <a [class]="!notification.dismissedDate ? 'notification-bull' :'notification-bull-read'">&bull;</a>
                {{notification.notification.content}}
            </p>
            <div *ngIf="notification.notification.deepLinkUri != null && notification.notification.deepLinkUri != ''">
                <a class="links" routerLink="{{notification.notification.deepLinkUri}}">
                    {{'notification.goToLink' | translate}} </a>
            </div>
        </div>
        <p class="item-date">{{ 'notification.from' | translate }} {{getDaysFromCreate()}} {{'notification.days'
            | translate }}
        </p>
    </div>
</div>