import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserNotification } from 'src/app/models/notification/notification.modal';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

  @Input() notification: UserNotification;
  @Input() userId: number;
  @Output() readNotification = new EventEmitter();
  
  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  getDaysFromCreate(){
    if(this.notification)
    {
      const today = DateTime.fromJSDate(new Date());
      const createdDate = DateTime.fromJSDate(new Date(this.notification.createdDate));
      return Math.round(today.diff(createdDate, 'day').days);
    }
    return 0;
  }

  markAsRead(){
    if(!this.notification.dismissedDate)
    {
      this.notification.dismissedDate = new Date();
      this.notificationService.markAsRead(this.notification.id, this.userId).subscribe(() => {
        this.readNotification.emit();
      });
    }
  }

}
