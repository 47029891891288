export const USE_HASH = false;

export const DEFAULT_GRID_PAGE_SIZE = 100;

export const SPECIAL_GASES_MINIMUM_COMPONENTS = 2;

export const DEFAULT_CHANNEL_CODE = 'MYLINDE';
export const DEFAULT_ORDER_ORIGIN = 'MyLindeWeb';

export const URL_IMG_DEFAULT = 'assets/images/default.png';

export const HEADER_MAX_USERACCOUNTS = 5;

export class SessionStorageItems {
	public static ID_TOKEN = 'idToken';
}

export enum StorageKeys {
	REQUESTS = 'requests',
	HOMECARE = 'homecare',
	HOMECARE_CCC = 'homecare_ccc',
	IG_CCC = 'IG_CCC',
	TECHNICAL_SERVICE = 'techinical service',
	CLAIMS = 'claims',
	CCC_USER_INVITATION = 'CCC_USER_INVITATION',
	ORDERS_IG_CCC = 'orders_ccc',
	HOMECARE_ORDER_CCC = 'homecare_order_ccc',
	HOMECARE_ORDER_MI = 'homecare_order_mi',
	CCC_CLAIMS = 'claims_ccc',
	SELECT_ACCOUNT = 'select_account',
	LEVEL_REPORTS = 'level_report',
	CCC_CONTACT_DATA = 'contact_data_ccc',
}

export enum OrderOriginCode {
	MYLINDE_WEB = 'MyLindeWeb',
	MYLINDE_APP = 'MyLIndeAPP',
	ERP = 'ERP',
}

export class CardStatusColor {
	public static colors: { [key: string]: string } = {
		REQUESTED: '#DC7800',
		PENDING: '#DC7800',
		ERROR: '#DC7800',
		ACEPTED: '#DC7800',
		PROGRAMED: '#DC7800',
		STANDBY: '#DC7800',
		INTRANSIT: '#DC7800',
		INPROCESSCANCELATION: '#DC7800',
		DELIVERED: '#00A0E1',
		CANCELED: '#E1000F',
		RETURNED: '#E1000F',
		REQUEST: '#99A0A0',
	};
}

export class Countries {
	//https://codepen.io/thiagofaleiro/pen/zqMKbR
	public static SIZE_BIG = 'size-big';
	public static SIZE_NORMAL = 'size-normal';
	public static SIZE_SMALL = 'size-small';
	public static SIZE_XSMALL = 'size-xsmall';

	public static flag_codes: { [key: string]: string } = {
		ar: 'Argentina',
		bs: 'Bahamas',
		bo: 'Bolivia',
		br: 'Brazil',
		cl: 'Chile',
		co: 'Colombia',
		cr: 'Costa Rica',
		cu: 'Cuba',
		ec: 'Ecuador',
		hn: 'Honduras',
		mx: 'Mexico',
		py: 'Paraguay',
		pe: 'Peru',
		pr: 'Puerto Rico',
		us: 'United States',
		uy: 'Uruguay',
		ve: 'Venezuela',
		at: 'Austria',
		be: 'Belgium',
		bg: 'Bulgaria',
		cz: 'Czech Republic',
		dk: 'Denmark',
		fi: 'Finland',
		de: 'Germany',
		gr: 'Greece',
		hu: 'Hungary',
		ie: 'Ireland',
		it: 'Italy',
		nl: 'Netherlands',
		ro: 'Romania',
		ru: 'Russia',
		es: 'Spain',
		fr: 'France',
		pl: 'Poland',
		pt: 'Portugal',
		se: 'Sweden',
		ch: 'Switzerland',
		tr: 'Turkey',
		ua: 'Ukraine',
	};
}
