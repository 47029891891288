import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
	PrivilegeService,
	QueryParamFilter,
	QueryStringService,
	SearchFilterService,
	SelectFilter,
} from 'factory-ng';
import { MenuItem } from 'primeng/api';
import { filter, interval, Subject, takeUntil } from 'rxjs';
import { StorageKeys } from 'src/app/constants';
import { EntityNames } from 'src/app/models/generic/entity.model';
import { ValueListReferences } from 'src/app/models/generic/value-list.model';
import UserAccount from 'src/app/models/security/user-account.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { GlobalVariablesService } from 'src/app/services/generic/global-constants.service';
import { MenuService } from 'src/app/services/generic/menu.service';
import { ValueListService } from 'src/app/services/generic/value-list.service';
import { MenuConfigurationService } from 'src/app/services/menu/menu-configuration.service';
import { UserAccountService } from 'src/app/services/security/user-account.service';
import { UserInfoService } from 'src/app/services/security/user-info.service';
import { environment } from 'src/environments/environment';
import { MenuConfigurationItem } from './utils/menu-configuration';
import { AccountTypes } from 'src/app/models/security/account-type.model';
import { OperationIndicatorsReportServiceService } from 'src/app/services/reports/operation-indicators-report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpResponse } from '@angular/common/http';
import { DateUtils } from 'src/app/models/generic/date-utils';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
	@ViewChild('logoText') logoText: ElementRef<HTMLDivElement>;

	imagePath: string = environment.imagesUrl;

	menuItemsLength = 0;

	menu: MenuItem[] = [];

	menuToggler = false;
	resetMenu = false;

	userAccount: UserAccount;
	userInfo: UserInfo;

	private readonly _destroying$ = new Subject<void>();

	constructor(
		private router: Router,
		private searchFilterService: SearchFilterService,
		private translateService: TranslateService,
		private valueListService: ValueListService,
		private privilegeService: PrivilegeService,
		private userInfoService: UserInfoService,
		private menuService: MenuService,
		private queryStringService: QueryStringService,
		private userAccountService: UserAccountService,
		private globalVariablesService: GlobalVariablesService,
		private menuConfigurationService: MenuConfigurationService,
		private operationService: OperationIndicatorsReportServiceService,
		private loadingService: NgxUiLoaderService
	) {}

	ngOnInit() {
		this.usersData();
	}

	usersData(): void {
		this.userAccountService.selectedUserAccount$
			.pipe(takeUntil(this._destroying$))
			.subscribe((userAccount) => {
				if (userAccount) {
					this.userAccount = userAccount;
					//Wait until translations are loaded to initialize the menu items
					this.translateService.get('dummy').subscribe((_) => {
						this.initializeMenu();
					});
				}
			});
		this.userInfoService.userInfo$.pipe(takeUntil(this._destroying$)).subscribe((userInfo) => {
			this.userInfo = userInfo;
		});
	}

	private initializeMenu() {
		if (this.globalVariablesService.isMobileSize) {
			this.menuToggler = true;
		}
		this.userInfoService.loadingPrivileges$
			.pipe(
				filter((l) => !l),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.menuConfigurationService.GetMenuConfiguration().subscribe((x) => {
					var menuItems = this.menuItemsWithPrivilegePermission(x);
					menuItems = this.menuItemTypePermission(menuItems);
					this.menu = menuItems.map((menuItem) => this.transformMenuItem(menuItem, true));
					this.menuItemsLength = this.menu.length;
				});
			});

		this.menuService.visibilityChange$
			.pipe(takeUntil(this._destroying$))
			.subscribe((menuVisibility) => {
				if (menuVisibility.menuState === false) {
					this.menu.forEach((item) => (item.expanded = false));
					this.resetMenu = true;
					setTimeout(() => {
						this.resetMenu = false;
					});
					// Add a CSS class for a specific animation during menu loading.
					this.logoText.nativeElement.classList.add('loading-menu');
					setTimeout(() => {
						this.logoText.nativeElement.classList.remove('loading-menu');
					}, 800);
				}
				this.menuToggler = menuVisibility.menuState;
				if (menuVisibility.menuState) {
					this.menu.forEach((menuItem) => {
						menuItem.tooltipOptions.disabled = true;
					});
				} else {
					this.menu.forEach((menuItem) => {
						menuItem.tooltipOptions.disabled = false;
					});
				}
			});
	}
	menuItemTypePermission(menuItems: MenuConfigurationItem[]): MenuConfigurationItem[] {
		if (
			this.userAccount?.account?.accountTypes.length > 0 &&
			this.userAccount?.account?.accountTypes[0]?.code == AccountTypes.CCC
		) {
			menuItems = menuItems.filter((m) => m.type != null && m.type.find((x) => x == 'CCC'));
		} else if (
			this.userAccount?.account?.accountTypes.length > 0 &&
			this.userAccount?.account?.accountTypes[0]?.code == AccountTypes.MEDICAL_INSURANCE
		) {
			menuItems = menuItems.filter((m) => m.type != null && m.type.find((x) => x == 'OS'));
		} else {
			menuItems = menuItems.filter(
				(m) =>
					!m.type ||
					(!m.type.find((x) => x == 'CCC') && !m.type.find((x) => x == 'OS')) ||
					m.type.find((x) => x == 'PAT' || x == 'CUST')
			);
		}
		return menuItems;
	}

	/**
	 * Transform Menu configurations items into PrimeNG menu items and check system permissions for each menu item
	 * @param menuItem Menu configuration item
	 * @returns A PrimeNG menu items
	 */
	transformMenuItem(menuItem: MenuConfigurationItem, firstIteration?: boolean): MenuItem {
		const menuItems = this.menuItemsWithPrivilegePermission(menuItem.items);
		return {
			label: this.translateService.instant(menuItem.label),
			icon: menuItem.icon,
			routerLink: menuItem.routerLink,
			routerLinkActiveOptions: menuItem.routerLinkActiveOptions,
			tooltipOptions: firstIteration
				? { tooltipLabel: this.translateService.instant(menuItem.label) }
				: null,
			styleClass: menuItem.styleClass,
			command: () => {
				this.toggleMenu(menuItem);
				if (menuItem.command) {
					this.navigateRequestsHistoryWithFilter(menuItem.command);
				}
				if (menuItem.label === 'contact.whatsapp') {
					window.open(environment.contactLinks.whatsapp, '_blank');
				}
				if (menuItem.label == 'reports.operationIndicators.title') {
					this.dowload();
				}
			},
			items: menuItems
				? menuItems.length >= 1
					? menuItems.map((item) => this.transformMenuItem(item))
					: this.transformMenuItem(menuItems[0])[0]
				: null,
		};
	}

	openContactLink(urlBase: String): void {
		let map = new Map<string, any>();
		map.set('cliente', this.userAccount.account.erpId);
		map.set('cod_cliente', this.userAccount.account.erpId);
		map.set('email', this.userInfo.email);
		map.set('origen', 'PORTAL');
		let queryString = this.queryStringService.getQueryString(map, []);
		window.open(urlBase + queryString, '_blank');
	}

	/**
	 * Filter the menu configuration items without privilege permissions
	 * @param menuItems The menu configuration items
	 * @returns menu configurations items with privilege permissions
	 */
	menuItemsWithPrivilegePermission(
		menuItems: MenuConfigurationItem[] | null
	): MenuConfigurationItem[] {
		if (!menuItems) {
			return null;
		}
		return menuItems.filter((menuItem) => {
			return this.privilegeService.isPrivilegeValid(menuItem.permissionsTag);
		});
	}

	private navigateRequestsHistoryWithFilter(requestType: string) {
		this.valueListService
			.getValueListItem(EntityNames.REQUEST, ValueListReferences.REQUEST_TYPE, requestType)
			.subscribe({
				next: (vl) => {
					//Save filter state before navigating
					let filter = new SelectFilter([], '', [vl.id], 'requestType', null, null);
					this.searchFilterService.savePanelFilterState(
						StorageKeys.REQUESTS,
						[new QueryParamFilter(filter.controlName, filter.getQueryString(), filter.getState())],
						null
					);
					this.router.navigate(['request-for-orders'], {
						state: { useStorageFilters: true },
					});
				},
				error: (error) => {
					//Navigate without filtering
					this.router.navigate(['request-for-orders']);
				},
			});
	}

	toggleMenu(menuItem?: MenuConfigurationItem): void {
		if (!this.globalVariablesService.isMobileSize && menuItem && this.menuToggler) {
			return;
		}

		if (this.globalVariablesService.isMobileSize && menuItem) {
			return;
		}

		if (this.globalVariablesService.isMobileSize) {
			this.menuService.forceToggle();
			return;
		}

		// Pass if window screen is not mobile.
		if (!menuItem || (menuItem && menuItem.items?.length && !menuItem.routerLink)) {
			this.menuService.toggleMenu();
		}
	}

	isMobileMode(): boolean {
		return window.innerWidth < 992;
	}

	ngOnDestroy(): void {
		this._destroying$.next();
		this._destroying$.complete();
	}

	dowload() {
		this.loadingService.start();
		this.operationService.downloadReportExcel().subscribe({
			next: (file: HttpResponse<Blob>) => {
				if (file) {
					const datatype = file.body.type;
					const binarydata = [];
					binarydata.push(file.body);
					const filePath = window.URL.createObjectURL(new Blob(binarydata, { type: datatype }));
					const dowloadLink = document.createElement('a');
					dowloadLink.href = filePath;
					var now = new Date();
					dowloadLink.setAttribute(
						'download',
						'MyLinde_Indicadores_' + DateUtils.toDateTimeStringId(now)
					);
					document.body.appendChild(dowloadLink);
					dowloadLink.click();

					this.loadingService.stop();
				}
			},
			error: (error) => {
				this.loadingService.stop();
			},
		});
	}
}
