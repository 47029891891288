import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageHandlerService } from "factory-ng";
import { catchError, concatMap, map, Observable, throwError } from "rxjs";
import ValueList from "src/app/models/generic/value-list.model";
import { environment } from "src/environments/environment";
import { EntityService } from "./entity.service";

@Injectable({
  providedIn: 'root'
})
export class ValueListService {
    
  constructor(
    private entityService: EntityService,
    private httpClient: HttpClient,
    private messageService: MessageHandlerService,
    private translateService: TranslateService
  ) {}


  getValueListsByEntityName(entityName: string, reference?: string, errorMessage?: string): Observable<ValueList[]> {
    return this.entityService.getEntityByName(entityName).pipe(concatMap(e => {
        if(e) {
            let params = new HttpParams().append('entityId', e.id);
            if(reference) {
              params = params.append('reference', reference);
            }
            return this.httpClient.get<ValueList[]>(`${environment.businessAPI}/value-lists`, { params: params });
        }
        return [];
    }), catchError((error) => {
            if(errorMessage) {
                return this.messageService.showErrorAndThrow(error, this.translateService.instant(errorMessage));
            }
            return throwError(() => new Error(error));
        }
    )); 
  }


  /**Retrieves a single value list item by entity, reference, and value */
  getValueListItem(entityName: string, reference: string, value: string, errorMessage?: string): Observable<ValueList> {
    return this.entityService.getEntityByName(entityName).pipe(concatMap(e => {
      if(e) {
          let params = new HttpParams()
            .append('entityId', e.id)
            .append('reference', reference)
            .append('value', value);
          
          return this.httpClient.get<ValueList[]>(`${environment.businessAPI}/value-lists`, { params: params })
            .pipe(map(vls => vls?.length ? vls[0] : null));
      }
      return null;
  }), catchError((error) => {
          if(errorMessage) {
              return this.messageService.showErrorAndThrow(error, this.translateService.instant(errorMessage));
          }
          return throwError(() => new Error(error));
      }
  ));
  }

}