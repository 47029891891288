// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	businessAPI: 'https://apimylinde-develop.linde.name/api/v1.0/business', //'http://localhost:50000/api',
	fileStorageAPI: 'https://apimylinde-develop.linde.name/api/v1.0/storage', //'http://localhost:19605/api',
	platformAPI: 'https://apimylinde-develop.linde.name/api/v1.0/platform', //'http://localhost:30000/api',
	notificationAPI: 'https://apimylinde-develop.linde.name/api/v1.0/notification', //'https://localhost:44318/api',

	production: false,
	msalConfig: {
		auth: {
			clientId: '44acbd64-e3d0-4a65-b80f-5a9e1c9ba092', // This is the ONLY mandatory field that you need to supply.
			redirectUri: '/home', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
			postLogoutRedirectUri: '/',
		},
	},
	apiConfig: {
		scopes: [
			'https://lindeb2cdev.onmicrosoft.com/202e7d4d-9ac0-4291-a08e-8a8521bf1b95/gateway.ReadWrite.All',
		],
		uri: 'https://apimylinde-develop.linde.name/api**', //'http://localhost**'
	},
	b2cPolicies: {
		names: {
			signUpSignIn: 'B2C_1A_V1_Linde_MyLindeArg_SignIn', //signUpSignIn - policyName
		},
		authorities: {
			signUpSignIn: {
				authority:
					'https://lindeb2cdev.b2clogin.com/lindeb2cdev.onmicrosoft.com/B2C_1A_V1_Linde_MyLindeArg_SignIn', //'https://b2cmylindedev.b2clogin.com/b2cmylindedev.onmicrosoft.com/b2c_1a_signup_signin', //'https://<your-tenant-name>.b2clogin.com/<your-tenant-name>.onmicrosoft.com/<your-sign-in-sign-up-policy>'
			},
		},
		authorityDomain: 'lindeb2cdev.b2clogin.com', // Mark your B2C tenant's domain as trusted. "<your-tenant-name>.b2clogin.com"
	},
	imagesUrl: 'https://apimylinde-release.linde.name/resources/images', //'assets/images',
	urlManuals: 'https://apimylinde-release.linde.name/resources/manuals/',
	contactLinks: {
		chat: 'https://appclienteslindectc.linde-gas.com/WebAPI802/EbChatLinde/index.html',
		call: 'https://appclienteslindectc.linde-gas.com/WebAPI802/ClicktoCallLinde/ClicktoCall/customer.jsp',
		whatsapp: 'https://wa.me/+5491144454633',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
