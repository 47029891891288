import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from '@angular/router';
import { PrivilegeGuard } from 'factory-ng';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { RedirectService } from '../generic/redirect.service';
import { UserAccountService } from './user-account.service';
import { UserInfoService } from './user-info.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
	constructor(
		private privilegeGuard: PrivilegeGuard,
		private redirectService: RedirectService,
		private router: Router,
		private userAccountService: UserAccountService,
		private userInfoService: UserInfoService
	) {}

	canLoad(
		route: Route,
		segments: UrlSegment[]
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.validateNavigation().pipe(
			switchMap((v: boolean | UrlTree) => {
				if (v === true) {
					//Validate privileges
					return this.userInfoService.loadingPrivileges$.pipe(
						filter((l) => !l), //Wait until loading is false
						take(1),
						switchMap((l) => this.privilegeGuard.canLoad(route, segments))
					);
				}
				return of(v);
			})
		);
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.validateNavigation(state?.url?.split('?')[0]).pipe(
			switchMap((v: boolean | UrlTree) => {
				if (v === true) {
					//Validate privileges
					return this.userInfoService.loadingPrivileges$.pipe(
						filter((l) => !l), //Wait until loading is false
						take(1),
						switchMap((l) => this.privilegeGuard.canActivate(next, state))
					);
				}
				return of(v);
			})
		);
	}

	private validateNavigation(redirectURL?: string): Observable<boolean | UrlTree> {
		return this.userAccountService.loadingUserAccounts$.pipe(
			filter((l) => !l), //Wait until loading is false
			switchMap((l) => {
				return this.userAccountService.haveEnableUserAccounts$.pipe(
					switchMap((a) => {
						return this.userAccountService.selectedUserAccount$.pipe(
							map((ua) => {
								if (!a) {
									return this.router.createUrlTree(['disable-account']);
								} else {
									//If terms have not been accepted, navigate to data policy
									if (!ua?.termsConditions) {
										if (redirectURL) {
											this.redirectService.setRedirectURL([redirectURL]);
										}
										return this.router.createUrlTree(['data-policy']);
									}
									return true;
								}
							})
						);
					})
				);
			})
		);
	}
}
