import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-disable-account',
	templateUrl: './disable-account.component.html',
	styleUrls: ['./disable-account.component.scss'],
})
export class DisableAccountComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
