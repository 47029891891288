import { Directive, ElementRef, OnInit } from '@angular/core';
import { URL_IMG_DEFAULT } from 'src/app/constants';

@Directive({
  selector: '[appImageNotFound]'
})
export class ImageNotFoundDirective implements OnInit {
  
  constructor(private host: ElementRef) { 

  }

  ngOnInit(): void {
    this.host.nativeElement.onerror = function() {
      this.onerror = null;
      this.src = URL_IMG_DEFAULT;
    
      }
  }

}

