import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalVariablesService } from './global-constants.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

    private expanded: boolean = false;

    visibilityChange$: Subject<{menuState: boolean, preventEvent: boolean}> = new Subject();

    constructor(private globalVariable: GlobalVariablesService) {
        if(this.globalVariable.isMobileSize) {
            this.expanded = true;
        }
    }

    toggleMenu(preventEvent?: boolean): void {
        if(this.globalVariable.isMobileSize) {
            return;
        }
        this.expanded = !this.expanded;
        this.visibilityChange$.next({ menuState: this.expanded, preventEvent: !!preventEvent});
    };

    openMenu(preventEvent?: boolean): void {
        this.expanded = true;
        this.visibilityChange$.next({ menuState: this.expanded, preventEvent: !!preventEvent});
    };

    forceToggle(preventEvent?: boolean): void {
        this.expanded = !this.expanded;
        this.visibilityChange$.next({ menuState: this.expanded, preventEvent: !!preventEvent});
    }

    closeMenu(preventEvent?: boolean): void {
        this.expanded = false;
        this.visibilityChange$.next({ menuState: this.expanded, preventEvent: !!preventEvent});
    };
}