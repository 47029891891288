import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { filter, Subject, takeUntil } from 'rxjs';
import { USE_HASH } from 'src/app/constants';
import { GlobalVariablesService } from 'src/app/services/generic/global-constants.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
	private readonly _destroying$ = new Subject<void>();

	isMobile = false;
	displayMenu: boolean = false;
	home: MenuItem;
	useHash: boolean = USE_HASH;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private globalVariablesService: GlobalVariablesService
	) {}

	ngOnInit(): void {
		this.translateService.get('routes.home').subscribe((t) => {
			this.home = { label: t, routerLink: '/home' };
		});

		if (this.globalVariablesService.isMobileSize) {
			this.isMobile = true;
		}

		this.displayMenu = this.shouldDisplayMenu();

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.displayMenu = this.shouldDisplayMenu();
			});
	}

	ngOnDestroy(): void {
		this._destroying$.next();
		this._destroying$.complete();
	}

	private shouldDisplayMenu(): boolean {
		let route = this.route;
		if (this.router.url === '/disable-account') {
			return false;
		}
		if (!route) {
			return true;
		}

		//Get the first occurence of displayMenu parameter among children routes
		while (
			route?.firstChild &&
			(route?.snapshot?.data?.['displayMenu'] === null ||
				route?.snapshot?.data?.['displayMenu'] === undefined)
		) {
			route = route?.firstChild;
		}
		//If the parameter has no value, return true by default
		return route?.snapshot?.data?.['displayMenu'] ?? true;
	}
}
