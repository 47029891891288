<div *ngIf="!loading; else loadingTemplate">
	<div class="flex justify-content-between align-items-center">
		<p class="grid-records-found">
			<span *ngIf="totalCount !== null && totalCount !== undefined">
				{{
					totalCount == 1
						? '1 ' + getTranslation('recordFound')
						: totalCount + ' ' + getTranslation('recordsFound')
				}}
			</span>
		</p>
	</div>

	<div
		*ngIf="visible"
		[style]="
			'height: ' +
			tableHeight +
			'; ' +
			'max-height: 35vh; ' +
			'min-height: calc(' +
			headerHeight +
			' + ' +
			virtualRowHeight +
			'px);'
		"
	>
		<p-table
			#table
			scrollDirection="horizontal"
			scrollHeight="flex"
			stateStorage="local"
			[columns]="columns"
			[lazy]="true"
			[rows]="pageSize"
			[scrollable]="true"
			[sortField]="defaultSortBy?.property"
			[sortOrder]="defaultSortBy?.direction === 'asc' ? 1 : -1"
			[stateKey]="useCacheSorting ? 'gridSort.' + storageName : null"
			[totalRecords]="totalCount"
			[value]="virtualRows"
			[virtualScroll]="true"
			[virtualScrollItemSize]="virtualRowHeight"
			(onLazyLoad)="loadLazy($event)"
			selectionMode="single"
			[(selection)]="selectedRow"
			(onRowSelect)="onRowSelect($event)"
		>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th
						*ngFor="let col of colsGridVisible"
						scope="col"
						pFrozenColumn
						[frozen]="col.frozen"
						[attr.sortableBy]="col.sortableBy ? col.sortableBy : ''"
						[pSortableColumn]="col.sortableBy"
					>
						<div class="flex">
							<div
								class="flex align-items-center"
								[style]="
									getColumnWidthStyle(col.width) +
									'justify-content:' +
									col.justifyContentHeader +
									';'
								"
							>
								{{ col.header | translate }}
								<p-sortIcon *ngIf="col.sortableBy != null" [field]="col.sortableBy"></p-sortIcon>
							</div>
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr [style]="getRowHeightStyle()" [pSelectableRow]="rowData">
					<ng-container *ngFor="let col of colsGridVisible">
						<td *ngIf="!col.showAsHTML" pFrozenColumn [frozen]="col.frozen">
							<div class="flex">
								<div
									class="cell-overflow"
									[style]="
										getColumnWidthStyle(col.width) + 'justify-content:' + col.justifyContent + ';'
									"
								>
									<button
										[pTooltip]="rowData[col.field] | cellGrid"
										[tooltipDisabled]="disableToolTip(rowData[col.field])"
										[tooltipPosition]="cellTooltipPosition"
										*ngIf="col.link"
										class="p-button-link p-0"
										label="{{ rowData[col.field] }}"
										pButton
										(click)="navigateCellLink(col, rowData)"
									></button>
									<span
										*ngIf="!col.link"
										[pTooltip]="rowData[col.field] | cellGrid"
										[tooltipDisabled]="disableToolTip(rowData[col.field])"
										[tooltipPosition]="cellTooltipPosition"
									>
										{{ rowData[col.field] }}
									</span>
								</div>
							</div>
						</td>

						<td *ngIf="col.showAsHTML" class="p-0" pFrozenColumn [frozen]="col.frozen">
							<div class="flex">
								<div
									class="cell-overflow"
									[innerHTML]="rowData[col.field]"
									[style]="getColumnWidthStyle(col.width)"
									(click)="navigateCellLink(col, rowData)"
								></div>
							</div>
						</td>
					</ng-container>
				</tr>
			</ng-template>
			<ng-template pTemplate="loadingbody" let-columns="columns">
				<tr [style]="getRowHeightStyle()">
					<td *ngFor="let col of colsGridVisible">
						<div class="p-d-flex">
							<div [style]="getColumnWidthStyle(col.width)">
								<p-skeleton
									class="p-skeleton"
									[ngStyle]="{ width: '100%', display: 'flex', height: '16px' }"
								></p-skeleton>
							</div>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="totalCount === 0" [style]="getRowHeightStyle()">
					<td [attr.colspan]="colsGridVisible.length">
						{{ getTranslation('noRecordsFound') }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<ng-template #loadingTemplate>
	<div class="m-3">
		<app-loading size="lg" [logoSize]="0"></app-loading>
	</div>
</ng-template>
