export default class Entity {
	id?: number | null;
	name?: string | null;
	description?: string | null;
}

export enum EntityNames {
	SPECIAL_GASES_REQUEST = 'SpecialGasesRequest',
	REQUEST = 'Request',
	PATIENT_REQUEST = 'PatientRequest',
	TECHNICAL_SERVICE = 'TechnicalService',
	CLAIMS = 'Claim',
	ORDER = 'Order',
	PATIENT_ORDER = 'PatientOrder',
	CONTACT_DATA = 'ContactDataChangeRequest',
	LEVEL_REPORT_DETAIL = 'LevelReportDetail',
	SALES_REQUEST = 'SalesRequest',
}
