import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

    isMobileSize: boolean;

    constructor() {
        this.setMobileBoolean();
        this.isMobileSize =  window.innerWidth < 992;
    }
    setMobileBoolean(): void {
        addEventListener("resize", (size) => {
            this.isMobileSize =  (size.target as Window).innerWidth < 992;
        });
    }
}