import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandlerService } from 'factory-ng';
import { catchError } from 'rxjs';
import Country from 'src/app/models/generic/countries.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CountriesService {
	constructor(
		private httpClient: HttpClient,
		private messageService: MessageHandlerService,
		private translateService: TranslateService
	) {}

	getCountries(countryCode?: string) {
		let params = new HttpParams();
		if (countryCode) {
			params = params.append('Code', countryCode);
		}
		return this.httpClient
			.get<Country[]>(`${environment.businessAPI}/countries`, { params: params })
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchErrorCountries')
					)
				)
			);
	}
}
