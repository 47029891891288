<div class="notification">
	<div [ngStyle]="{ top: '-' + notificationScroll + 'px' }" class="notification-container">
		<p-virtualScroller
			[value]="notifications"
			[itemSize]="100"
			[lazy]="true"
			(onLazyLoad)="loadNotificationsLazy($event)"
		>
			<ng-template pTemplate="header">
				<div class="flex align-items-center w-full justify-content-between">
					<h2>Notificaciones</h2>
					<div>
						<button
							class="p-button-icon"
							icon="pi pi-inbox"
							(click)="markAllAsRead()"
							pButton
							type="button"
							pTooltip="{{ 'notification.markAllAsRead' | translate }}"
							tooltipPosition="left"
						></button>
						<button
							class="p-button-icon"
							icon="pi pi-envelope"
							*ngIf="!showingUnread"
							(click)="changeShowUnread()"
							pButton
							type="button"
							pTooltip="{{ 'notification.noUnread' | translate }}"
							tooltipPosition="left"
						></button>
						<button
							class="p-button-icon"
							style="color: #005591"
							icon="pi pi-envelope"
							*ngIf="showingUnread"
							(click)="changeShowUnread()"
							pButton
							type="button"
							pTooltip="{{ 'notification.read' | translate }}"
							tooltipPosition="left"
						></button>
					</div>
				</div>
			</ng-template>
			<ng-template let-notification pTemplate="item" let-i="index">
				<div
					class="ion-text-center Body-M-Regular ion-no-padding"
					*ngIf="!isLoading && notifications.length <= 0"
				>
					<p>{{ 'notification.noNotifications' | translate }}</p>
				</div>
				<div *ngIf="!isLoading && notifications.length > 0">
					<app-notification-item
						[notification]="notification"
						[userId]="notification.id"
						style="width: 100%"
						(readNotification)="onMarkAsReadNotification()"
					>
					</app-notification-item>
				</div>
			</ng-template>
		</p-virtualScroller>
	</div>
</div>
