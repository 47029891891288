<div class="tank">
	<div class="tank-container relative">
		<div class="tank-image">
			<img class="w-full" [src]="imagesUrl + '/tank-level/tank.png'" appImageNotFound />
		</div>
		<div
			class="percent-bar flex flex-wrap flex-column-reverse justify-content-center align-content-center"
		>
			<div *ngFor="let tankLevel of tankLevels; let i = index" class="view-bar relative">
				<div class="progress-container">
					<div [style]="'bottom: ' + checkTankLevel(tankLevel, i) + 'px'" class="progress"></div>
				</div>
			</div>
		</div>
		<div class="data-text-container">
			<div *ngIf="calculatedTankLevelPercent" class="data-text relative">
				<p class="percent-text">{{ calculatedTankLevelPercent.toFixed(0) }}%</p>
				<div [ngStyle]="{ 'top.px': levelReportTopProperty() }" class="limit-info limit-info-left">
					<p>
						{{ 'requests.bulks.form.tankLevel.label' | translate }}:
						{{ tankLevel ? tankLevel : '0' }}
						{{ tankLevel ? (unit | titlecase) : '' }}
					</p>
				</div>
			</div>
		</div>
		<div class="data-text-container">
			<div *ngIf="calculatedTankLevelPercent" class="data-text relative">
				<p class="percent-text">{{ calculatedTankLevelPercent.toFixed(0) }}%</p>
				<div class="limit-info">
					<p>
						{{ 'requests.bulks.form.tankLevel.max' | translate }}:
						{{ maxValue ? maxValue : '0' }}
						{{ maxValue ? (unit | titlecase) : '' }}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
