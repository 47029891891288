<footer class="layout-footer">
	<div class="footer-container">
		<span class="footer-copyright">&copy; {{ 'footer.copyright' | translate }}</span>
		<span class="footer-copyright cursor-pointer" (click)="navigateToDataPolicy()">{{
			'footer.dataPolicy' | translate
		}}</span>
		<span class="footer-copyright">{{ 'footer.downloadApp' | translate }} </span>
		<div class="app-images flex gap-3">
			<div class="app-image">
				<a href="https://apps.apple.com/us/app/my-linde/id1668844686" target="_blank">
					<img [src]="imagePath + '/layout/footer/iphone.png'" appImageNotFound />
				</a>
			</div>
			<div class="app-image">
				<a href="https://play.google.com/store/apps/details?id=app.mylinde" target="_blank">
					<img [src]="imagePath + '/layout/footer/android.png'" appImageNotFound />
				</a>
			</div>
		</div>
	</div>
</footer>
