<div class="layout-wrapper flex flex-column align-content-between">
	<div class="web-content flex flex-grow-1">
		<app-menu *ngIf="displayMenu"></app-menu>
		<div class="web-wrapper w-full flex flex-column">
			<app-header></app-header>
			<div id="page-content" class="flex mt-8 lg:m-0 flex-1">
				<div class="layout-content flex-grow-1 md:p-3 px-3">
					<div class="hidden md:flex justify-content-between">
						<app-breadcrumbs [home]="home" [useHash]="useHash"></app-breadcrumbs>
						<app-navigate-back>
							<button
								class="p-button-link p-0"
								label="{{ 'general.goBack' | translate }}"
								pButton
							></button>
						</app-navigate-back>
					</div>
					<div class="h-full">
						<router-outlet></router-outlet>
					</div>
				</div>
				<div class="sidebar-content" [ngClass]="{ 'mobile-active': isMobile }">
					<app-sidebar></app-sidebar>
				</div>
			</div>
		</div>
	</div>
	<div class="web-footer">
		<app-footer></app-footer>
	</div>
</div>
