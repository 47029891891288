import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandlerService, QueryParamFilter, QueryStringService, SortBy } from 'factory-ng';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, catchError, filter, map, Observable, switchMap, take } from 'rxjs';
import AccountUserInfoInvitation from 'src/app/models/ccc-user-invitation/user-info-invitation.model';
import { AccountTypes } from 'src/app/models/security/account-type.model';
import UserAccount from 'src/app/models/security/user-account.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserAccountService {
	private readonly _countUserAccounts$ = new BehaviorSubject<number>(undefined);
	countUserAccounts$: Observable<number> = this._countUserAccounts$.asObservable();

	private readonly _selectedUserAccount$ = new BehaviorSubject<UserAccount>(undefined);
	selectedUserAccount$: Observable<UserAccount> = this._selectedUserAccount$.asObservable();

	private readonly _loadingUserAccounts$ = new BehaviorSubject<boolean>(true);
	loadingUserAccounts$: Observable<boolean> = this._loadingUserAccounts$.asObservable();

	private readonly _haveEnableUserAccounts$ = new BehaviorSubject<boolean>(true);
	haveEnableUserAccounts$: Observable<boolean> = this._haveEnableUserAccounts$.asObservable();

	constructor(
		private httpClient: HttpClient,
		private messageService: MessageHandlerService,
		private translateService: TranslateService,
		private queryStringService: QueryStringService,
		private router: Router,
		private loadingService: NgxUiLoaderService
	) {}

	getUserAccountsByUserId(
		userId: number,
		pageSize?: number,
		pageNumber?: number,
		sortBy?: SortBy,
		filters: QueryParamFilter[] = []
	): Observable<HttpResponse<UserAccount[]>> {
		let map = new Map<string, any>();
		map.set('pageNumber', pageNumber);
		map.set('pageSize', pageSize);
		map.set('sortBy', sortBy);

		let queryString = this.queryStringService.getQueryString(map, filters);
		if (queryString) {
			queryString = queryString + '&userId=' + userId;
		} else {
			queryString = '?userId=' + userId;
		}

		return this.httpClient
			.get<UserAccount[]>(`${environment.businessAPI}/user-accounts${queryString}`, {
				observe: 'response',
			})
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchUserError')
					)
				)
			);
	}

	initializeUserAccounts(ui: UserInfo) {
		this._selectedUserAccount$.next(null);
		this._loadingUserAccounts$.next(true);
		if (ui) {
			//first account associated with the user
			const defaultSortBy: SortBy = {
				direction: 'asc',
				property: 'id',
			};
			//get one user account enable
			this.getUserAccountsByUserId(ui.id, 1, 0, defaultSortBy).subscribe({
				next: (response) => {
					this._countUserAccounts$.next(parseInt(response.headers.get('total-count')));
					//this._userAccounts$.next(userAccounts ?? []);
					if (response.body?.length) {
						//Select first account by default
						this._selectedUserAccount$.next(response.body[0]);
						this._haveEnableUserAccounts$.next(true);
					} else {
						this._haveEnableUserAccounts$.next(false);
					}
					this._loadingUserAccounts$.next(false);
				},
				error: (error) => {
					this._loadingUserAccounts$.next(false);
					this._haveEnableUserAccounts$.next(false);
				},
			});
		}
	}

	isClientOrPatient(ua) {
		if (
			ua.account.accountTypes &&
			ua.account.accountTypes.length > 0 &&
			ua.account.accountTypes[0].code == AccountTypes.CUSTOMER
		) {
			return true;
		} else if (
			ua.account.accountTypes &&
			ua.account.accountTypes.length > 0 &&
			ua.account.accountTypes[0].code == AccountTypes.WAREHOUSE
		) {
			return true;
		} else if (
			ua.account.accountTypes &&
			ua.account.accountTypes.length > 0 &&
			ua.account.accountTypes[0].code == AccountTypes.PATIENT
		) {
			return true;
		} else {
			return false;
		}
	}

	isMedicalInsurance(ua) {
		if (
			ua.account.accountTypes &&
			ua.account.accountTypes.length > 0 &&
			ua.account.accountTypes[0].code == AccountTypes.MEDICAL_INSURANCE
		) {
			return true;
		} else {
			return false;
		}
	}

	setAccountSelected(ua: UserAccount) {
		this.loadingService.start();
		this._selectedUserAccount$.next(ua);
		if (this.isClientOrPatient(ua)) {
			this.router.navigate(['/home']);
		} else if (this.isMedicalInsurance(ua)) {
			this.router.navigate(['/dashboard-medical-insurance']);
		} else {
			this.router.navigate(['/ccc-orders']);
		}
		this.loadingService.stop();
	}
	acceptTermsConditions(): Observable<UserAccount> {
		return this._selectedUserAccount$.pipe(
			filter((ua) => !!ua),
			take(1),
			switchMap((ua) => {
				let body = { TermsConditions: true };
				return this.httpClient
					.post<UserAccount>(`${environment.businessAPI}/user-accounts/${ua.id}`, body)
					.pipe(
						map((response) => {
							if (response) {
								this._selectedUserAccount$.next(response);
							}
							return response;
						}),
						catchError((error) =>
							this.messageService.showErrorAndThrow(
								error,
								this.translateService.instant('messages.acceptTermsError')
							)
						)
					);
			})
		);
	}

	getUserAccountsCCCFromUser(): Observable<UserAccount[]> {
		return this.httpClient.get<UserAccount[]>(`${environment.businessAPI}/user-accounts/ccc`).pipe(
			map((response) => {
				return response;
			}),
			catchError((error) =>
				this.messageService.showErrorAndThrow(
					error,
					this.translateService.instant('messages.fetchUserError')
				)
			)
		);
	}

	getAccountsUsersInfo(
		pageSize?: number,
		pageNumber?: number,
		sortBy?: SortBy,
		filters: QueryParamFilter[] = []
	): Observable<HttpResponse<AccountUserInfoInvitation[]>> {
		let map = new Map<string, any>();
		map.set('pageNumber', pageNumber);
		map.set('pageSize', pageSize);
		map.set('sortBy', sortBy);

		let queryString = this.queryStringService.getQueryString(map, filters);

		return this.httpClient
			.get<AccountUserInfoInvitation[]>(
				`${environment.businessAPI}/user-accounts/UserAccountGrid${queryString}`,
				{ observe: 'response' }
			)
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchRequestsError')
					)
				)
			);
	}

	removeInvitation(row: AccountUserInfoInvitation) {
		const header: HttpHeaders = new HttpHeaders({
			'if-Match': row.version,
		});
		return this.httpClient
			.post<AccountUserInfoInvitation>(
				`${environment.businessAPI}/user-accounts/${row.userAccountId}/delete`,
				null,
				{
					headers: header,
				}
			)
			.pipe(catchError((error) => this.messageService.handleError(error)));
	}
}
