import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

    private _redirectURL: any[];

    constructor(private router: Router) {}


    canRedirect(): boolean {
        return !!this._redirectURL?.length;
    }

    
    clearRedirectURL() {
        this._redirectURL = null;
    }

    
    setRedirectURL(url: any[]) {
        this._redirectURL = url;
    }


    redirect() {
        this.router.navigate(this._redirectURL);
        this.clearRedirectURL();
    }

}