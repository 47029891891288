import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandlerService, Privilege, PrivilegeService } from 'factory-ng';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import ComboItem from 'src/app/models/generic/combo-item.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserInfoService {
	private readonly _loadingPrivileges$ = new BehaviorSubject<boolean>(true);
	get loadingPrivileges$(): Observable<boolean> {
		return this._loadingPrivileges$.asObservable();
	}

	private readonly _userInfo$ = new BehaviorSubject<UserInfo>(undefined);
	userInfo$: Observable<UserInfo> = this._userInfo$.asObservable();

	constructor(
		private httpClient: HttpClient,
		private messageService: MessageHandlerService,
		private privilegeService: PrivilegeService,
		private translateService: TranslateService
	) {}

	getLoggedInUserInfo(): Observable<UserInfo> {
		return this.httpClient
			.get<UserInfo>(`${environment.platformAPI}/admin/users/userinfo`)
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchLoggedInUserInfoError')
					)
				)
			);
	}

	getUserPrivileges(): Observable<Privilege[]> {
		return this.httpClient
			.get<Privilege[]>(`${environment.platformAPI}/.me/privileges`)
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchLoggedInUserInfoError')
					)
				)
			);
	}

	getCccCombo(): Observable<ComboItem[]> {
		return this.httpClient
			.get<Privilege[]>(`${environment.platformAPI}/admin/users/ccc-combo`)
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchLoggedInUserInfoError')
					)
				)
			);
	}

	/**Initializes UserInfo and Privileges data */
	initializeUserInfo() {
		return this.getLoggedInUserInfo().subscribe({
			next: (ui) => {
				this._userInfo$.next(ui);
				this.initializeUserPrivileges();
			},
			error: (error) => this._userInfo$.next(null),
		});
	}

	private initializeUserPrivileges() {
		this._loadingPrivileges$.next(true);
		this.getUserPrivileges().subscribe((ps) => {
			this.privilegeService.savePrivileges(ps);
			this._loadingPrivileges$.next(false);
		});
	}
}
