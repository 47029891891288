import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoadingModule, SearchFilterModule } from 'factory-ng';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ImageModule } from 'primeng/image';
import { DialogModule } from 'primeng/dialog';
import { TimelineModule } from 'primeng/timeline';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeAR from '@angular/common/locales/es-AR';
import { FrequentQuestionsComponent } from './site/frequent-questions/frequent-questions.component';
import { ContactComponent } from './site/contact/contact.component';
import { MainModule } from './layout/main/main.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalRedirectComponent,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { GenericModule } from './generic/generic.module';
import { DisableAccountComponent } from './disable-account/disable-account.component';

registerLocaleData(localeAR);

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#005591',
	bgsOpacity: 0.5,
	bgsPosition: 'bottom-right',
	bgsSize: 60,
	bgsType: 'double-bounce',
	blur: 5,
	delay: 0,
	fastFadeOut: true,
	fgsColor: '#005591',
	fgsPosition: 'center-center',
	fgsSize: 60,
	fgsType: 'folding-cube',
	gap: 24,
	logoPosition: 'center-center',
	logoSize: 54,
	logoUrl: environment.imagesUrl + '/logo1.png',
	masterLoaderId: 'master',
	overlayBorderRadius: '0',
	overlayColor: 'rgba(40, 40, 40, 0.8)',
	pbColor: '#005591',
	pbDirection: 'ltr',
	pbThickness: 3,
	hasProgressBar: true,
	text: '',
	textColor: '#FFFFFF',
	textPosition: 'center-center',
	maxTime: -1,
	minTime: 300,
};

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
	// console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msalConfig.auth.clientId, // This is the ONLY mandatory field that you need to supply.
			authority: environment.b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
			redirectUri: environment.msalConfig.auth.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
			postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri, // Indicates the page to navigate after logout.
			knownAuthorities: [environment.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
			navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
		},
		cache: {
			cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
			storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Verbose,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();

	protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);
	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
		authRequest: {
			extraQueryParameters: {
				ui_locales: 'es',
			},
		},
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: [...environment.apiConfig.scopes],
			extraQueryParameters: {
				ui_locales: 'es',
			},
		},
		loginFailedRoute: '/unauthorized',
	};
}

@NgModule({
	declarations: [
		AppComponent,
		FrequentQuestionsComponent,
		ContactComponent,
		UnauthorizedComponent,
		DisableAccountComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		LoadingModule,
		SearchFilterModule,
		PanelMenuModule,
		InputTextModule,
		ButtonModule,
		CardModule,
		HttpClientModule,
		MsalModule,
		TableModule,
		CheckboxModule,
		ConfirmDialogModule,
		ImageModule,
		ToastModule,
		DialogModule,
		TimelineModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		MainModule,
		GenericModule,
	],
	providers: [
		MessageService,
		ConfirmationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
