import { Component, Inject } from '@angular/core';
import {
	MsalBroadcastService,
	MsalGuardConfiguration,
	MsalService,
	MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
	SsoSilentRequest,
} from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionStorageItems } from './constants';
import { SessionStorageService } from './services/generic/storage.service';
import { UserAccountService } from './services/security/user-account.service';
import { UserInfoService } from './services/security/user-info.service';
import { UserRoleService } from './services/security/user.role.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
	acr?: string;
	tfp?: string;
};

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'My Linde';
	isIframe = false;
	isLogin = false;
	showExpiredTokenError = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private translateService: TranslateService,
		private config: PrimeNGConfig,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private sessionStorage: SessionStorageService,
		private userAccountService: UserAccountService,
		private userInfoService: UserInfoService,
		private userRoleService: UserRoleService
	) {}

	ngOnInit(): void {
		this.translateService.setDefaultLang('es-AR');
		this.translateService.use('es-AR').subscribe(() => {
			this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
		});

		var a = new URLSearchParams(window.location.hash);
		var err = a.get('error_description');
		this.showExpiredTokenError = err && err.startsWith('AADB2C90208');
		this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
		this.setIsLogin();

		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
				)
			)
			.subscribe((result: EventMessage) => {
				if (this.authService.instance.getAllAccounts().length === 0) {
					window.location.pathname = '/';
				} else {
					this.setIsLogin();
				}
			});

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.setIsLogin();
				this.checkAndSetActiveAccount();
				this.initializeUserData();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGIN_SUCCESS ||
						msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
						msg.eventType === EventType.SSO_SILENT_SUCCESS
				),
				takeUntil(this._destroying$)
			)
			.subscribe((result: EventMessage) => {
				let payload = result.payload as AuthenticationResult;
				let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

				if (
					idtoken.acr === environment.b2cPolicies.names.signUpSignIn ||
					idtoken.tfp === environment.b2cPolicies.names.signUpSignIn
				) {
					this.authService.instance.setActiveAccount(payload.account);
					this.setIsLogin();
					//save id token in sessionstorage
					let idtokenhint = payload.idToken;
					this.sessionStorage.set(SessionStorageItems.ID_TOKEN, idtokenhint);
				}
				return result;
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
				takeUntil(this._destroying$)
			)
			.subscribe((result: EventMessage) => {
				if (result.error.message.includes('AADB2C90091')) {
					window.location.pathname = '/';
				}
			});

		this.msalBroadcastService.msalSubject$
			.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS))
			.subscribe((result: EventMessage) => {
				this.sessionStorage.cleanAll();
				this.setIsLogin();
			});
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		let activeAccount = this.authService.instance.getActiveAccount();

		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			let accounts = this.authService.instance.getAllAccounts();
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}

	loginRedirect() {
		if (this.msalGuardConfig.authRequest) {
			this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
		} else {
			this.authService.loginRedirect();
		}
	}

	login(userFlowRequest?: RedirectRequest | PopupRequest) {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(response.account);
					});
			} else {
				this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({
					...this.msalGuardConfig.authRequest,
					...userFlowRequest,
				} as RedirectRequest);
			} else {
				this.authService.loginRedirect(userFlowRequest);
			}
		}
	}

	logout() {
		const logoutHint = this.sessionStorage.get(SessionStorageItems.ID_TOKEN);
		this.authService.logoutRedirect({ idTokenHint: logoutHint });
	}

	setIsLogin() {
		this.isLogin = this.authService.instance.getAllAccounts().length > 0;
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

	private initializeUserData() {
		this.userInfoService.initializeUserInfo();
		this.userInfoService.userInfo$.pipe(takeUntil(this._destroying$)).subscribe((ui) => {
			if (ui != undefined && ui != null) {
				this.userAccountService.initializeUserAccounts(ui);
				this.userRoleService.initializeUserRoles(ui);
			}
		});
	}
}
