import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
	GridColumn,
	IFilter,
	QueryParamFilter,
	RowAction,
	RowsDataChange,
	SearchFilterService,
	SortBy,
} from 'factory-ng';
import { LazyLoadEvent } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { DEFAULT_GRID_PAGE_SIZE, StorageKeys } from 'src/app/constants';
import Country from 'src/app/models/generic/countries.model';
import { AccountTypes } from 'src/app/models/security/account-type.model';
import Account from 'src/app/models/security/account.model';
import UserAccount from 'src/app/models/security/user-account.model';
import UserInfo from 'src/app/models/security/user-info.model';
import { AccountService } from 'src/app/services/security/account.service';
import { UserAccountService } from 'src/app/services/security/user-account.service';
import { UserInfoService } from 'src/app/services/security/user-info.service';

@Component({
	selector: 'app-select-account',
	templateUrl: './select-account.component.html',
	styleUrls: ['./select-account.component.scss'],
})
export class SelectAccountComponent implements OnInit {
	private readonly _destroying$ = new Subject<void>();

	@Input() visible: boolean = false;
	@Output() onCancel = new EventEmitter<void>();
	@Output() visibleChange = new EventEmitter<boolean>();

	columns: GridColumn[];
	fetchError: boolean = false;
	loading: boolean = true;
	pageSize: number = DEFAULT_GRID_PAGE_SIZE;
	rowActions: RowAction[] = [];
	rows: UserAccount[] = [];
	rowsDataChange: RowsDataChange;
	storageName: string = StorageKeys.SELECT_ACCOUNT;

	totalCount: number;

	userInfo: UserInfo;
	formInput: UntypedFormGroup = new UntypedFormGroup({
		searchQuery: new FormControl<string | null>(null),
		countryCode: new FormControl<string | null>(null),
	});
	countryOptions: Country[] = [];
	showCountryOptions: boolean = false;

	constructor(
		private userAccountService: UserAccountService,
		private userInfoService: UserInfoService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.configureGridColumns();
		this.fetchCountryOptions();
		this.userInfoService.userInfo$.pipe(takeUntil(this._destroying$)).subscribe((user) => {
			this.userInfo = user;
			if (user) {
				this.search();
			}
		});
	}
	private configureGridColumns() {
		let index = 0;
		this.columns = [
			new GridColumn(index++, 'document', 'selectAccount.grid.document', '80px'),
			new GridColumn(index, 'accountCompanyName', 'selectAccount.grid.companyName', '150px'),
			new GridColumn(
				index++,
				'deliveryLocationId',
				'selectAccount.grid.deliveryLocationErpId',
				'90px'
			),
			new GridColumn(index++, 'deliveryLocationAddress', 'selectAccount.grid.fullAddress', '150px'),
			new GridColumn(index++, 'customerType', 'Tipo de cliente', '70px'),
			new GridColumn(index++, 'isEnabled', 'Habilitado', '70px'),
		];
	}

	private fetchCountryOptions() {
		this.userAccountService.getUserAccountsCCCFromUser().subscribe({
			next: (response) => {
				if (response && response.length > 0) {
					this.showCountryOptions = true;
					this.countryOptions = response.map(
						(u) => new Country(u.account.countryCode, u.account.countryName)
					);
					this.formInput.get('countryCode').patchValue(this.countryOptions[0].code);
				}
			},
			error: () => {},
		});
	}
	onCountryChange(event) {
		this.getGridTotalCount();
	}

	getGridTotalCount() {
		this.totalCount = 0;
		this.loading = true;
		let filters = [
			new QueryParamFilter('SearchQuery', this.getQueryString('searchQuery'), null),
			new QueryParamFilter('CountryCode', this.getQueryString('countryCode'), null),
		];
		this.userAccountService
			.getUserAccountsByUserId(this.userInfo.id, 1, 0, null, filters)
			.subscribe({
				next: (response) => {
					this.totalCount = parseInt(response.headers.get('total-count'));
					this.loading = false;
				},
				error: () => {
					this.totalCount = 0;
					this.loading = false;
				},
			});
	}

	onAddText(event = null) {
		if (!event || event.code === 'Enter') {
			this.search();
		}
	}

	search() {
		if (this.formInput.valid) {
			this.getGridTotalCount();
		}
	}

	getQueryString(formControlName: string) {
		const value = this.formInput.get(formControlName).value;
		if (value == undefined || value == null) {
			return [''];
		} else {
			return [formControlName + '=' + value];
		}
	}

	loadLazy(event) {
		event.pagesToLoad.forEach((pageNumber) => {
			this.fetchAccounts(pageNumber, event.sortBy);
		});
	}

	setTypeUser(code) {
		if (code == AccountTypes.PATIENT) {
			return this.translateService.instant('general.patient');
		}
		if (code == AccountTypes.CCC) {
			return this.translateService.instant('general.CCC');
		}
		if (code == AccountTypes.MEDICAL_INSURANCE) {
			return this.translateService.instant('general.OS');
		}
		if (code == AccountTypes.CUSTOMER) {
			return this.translateService.instant('general.customer');
		}
		if (code == AccountTypes.WAREHOUSE) {
			return this.translateService.instant('general.warehouse');
		}
		return null;
	}

	private fetchAccounts(pageNumber: any, sortBy: any) {
		const defaultSortBy: SortBy = {
			direction: 'asc',
			property: 'id',
		};
		let filters = [
			new QueryParamFilter('SearchQuery', this.getQueryString('searchQuery'), null),
			new QueryParamFilter('CountryCode', this.getQueryString('countryCode'), null),
		];
		this.userAccountService
			.getUserAccountsByUserId(this.userInfo.id, this.pageSize, pageNumber, defaultSortBy, filters)
			.subscribe({
				next: (response) => {
					this.rows = response.body.map((r) => {
						//Use the constructor to initialize class properties
						let account = new UserAccount(r);
						account.document = account.account.erpId;
						account.customerType = this.setTypeUser(account.account?.accountTypes[0]?.code);
						account.isEnabled = !account.account.enable
							? this.translateService.instant('general.no')
							: this.translateService.instant('general.yes');
						return account;
					});
					this.rowsDataChange = {
						pageNumber: pageNumber,
						sortBy: sortBy,
					};
				},
				error: (error) => {
					this.rows = [];
				},
			});
	}

	onShow() {
		this.getGridTotalCount();
	}
	onCancelClick() {
		this.formInput.get('searchQuery').patchValue(null);
		this.onCancel.emit();
	}

	onAccountSelected(event) {
		this.userAccountService.setAccountSelected(event.data);
		this.visible = false;
	}

	ngOnDestroy(): void {
		this._destroying$.next();
		this._destroying$.complete();
	}
}
