export default class Country {
	code: string | null;
	name: string | null;
	internationalCallingCode: string | null;
	mobilePrefix: string | null;
	iGSystemCode: string | null;
	hCSystemCode: string | null;
	bulkSystemCode: string | null;
	emailCountryCCC: string | null;
	phoneCountryCCC: string | null;
	web: string | null;
	schedule: string | null;
	additionalReference: string | null;

	enabled: boolean | null;
	bulkCutOffTime?: number | null;
	
	constructor(code: string, name:string){
		this.code = code;
		this.name = name;
	}
}
