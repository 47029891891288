<div class="layout-header">
	<div class="web-header">
		<div class="header-gradient"></div>
		<div class="header-container">
			<span class="input-filter p-input-icon-left">
				<i class="pi pi-search"></i>
				<input type="text" pInputText placeholder="Buscar" />
			</span>
			<div class="profile flex align-content-center">
				<p-splitButton
				*ngIf="userAccount && countUserAccounts <= maxUserAccountHeader"
					appendTo="body"
					[model]="userAccountsItems"
					styleClass="p-button-text p-button-success mr-2 mb-2 pt-2"
					[ngClass]="{ 'single-account': countUserAccounts === 1 }"
				>
					<ng-template pTemplate="content">
						<span class="ml-2 flex align-items-center font-bold company-name">
							<app-flag
								*ngIf="isCCC()"
								[countryCode]="userAccount?.account?.countryCode"
							></app-flag>
							{{ userAccount?.account?.companyName }}
						</span>
					</ng-template>
				</p-splitButton>

				<div *ngIf="userAccount && countUserAccounts > maxUserAccountHeader" class="p-button-text p-button-success mr-2 mb-2 p-splitbutton p-component pt-2" >
					<button (click)="onClickSelectAccount()" type="button" pbutton="" class="p-element p-splitbutton-defaultbutton p-button p-component ng-star-inserted" ng-reflect-icon-pos="left">
						<span class="ml-2 flex align-items-center font-bold company-name ng-star-inserted"> 
							<app-flag  [countryCode]="userAccount?.account?.countryCode"></app-flag>
							{{ userAccount?.account?.companyName }} 
						</span>
					</button>
				  <button (click)="onClickSelectAccount()" type="button" pbutton="" icon="pi pi-chevron-down" class="p-element p-splitbutton-menubutton p-button p-component p-button-icon-only" >
					<span class="p-button-icon pi pi-chevron-down" aria-hidden="true">
				  	</span>
				   </button>
				</div>

				<div *ngIf="hasPrivileges()" class="align-self-center">
					<p-badge
						value="{{ countNotifications }}"
						class="notifications-badge"
						*ngIf="countNotifications > 0"
					></p-badge>
					<button
						pButton
						pRipple
						type="button"
						value="2"
						icon="pi pi-bell"
						class="p-button-rounded p-button-text bell-button"
						(click)="goToNotification()"
					></button>
				</div>
				<div class="align-self-center pt-2">
					<p-splitButton
						[model]="headerMenuItems"
						appendTo="body"
						[showTransitionOptions]="'0ms'"
						[hideTransitionOptions]="'0ms'"
						styleClass="header-menu p-button-text p-button-success mx-2 mb-2"
					>
						<ng-template pTemplate="content"> </ng-template>
					</p-splitButton>
				</div>
			</div>
		</div>
	</div>
				
	<p-sidebar [(visible)]="displayNotif" position="right" [modal]="false">
		<app-notification></app-notification>
	</p-sidebar>

	<app-select-account
		[(visible)]="showSelectAccountPopUp"
		(onCancel)="handleSelectAccountCancelled()"
		(onSaveSuccess)="handleInvitationSaved()"
	></app-select-account>
</div>
