/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryStringService, SortBy } from 'factory-ng';
import { BehaviorSubject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserNotification, UserStats } from '../../models/notification/notification.modal';
@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private _notifications = new BehaviorSubject<UserNotification[]>([]);
	private _countUnread = new BehaviorSubject<number>(0);

	constructor(private http: HttpClient, private queryStringService: QueryStringService) {}

	public get notifications() {
		return this._notifications.asObservable();
	}
	public get countUnread() {
		return this._countUnread.asObservable();
	}

	getCountUnreadNotifications(userId: number) {
		return this.http.get<UserStats>(environment.notificationAPI + `/users/${userId}/stats`).pipe(
			tap((count) => {
				this._countUnread.next(count.notReadCount);
			})
		);
	}

	fetchNotifications(
		userId: number,
		pageSize?: number,
		pageNumber?: number,
		sortBy?: SortBy,
		keepSearch: boolean = false
	) {
		let map = new Map<string, any>();
		map.set('pageNumber', pageNumber);
		map.set('pageSize', pageSize);
		map.set('sortBy', sortBy);

		var queryString = this.queryStringService.getQueryString(map, []);
		return this.http
			.get<UserNotification[]>(
				environment.notificationAPI + `/users/${userId}/notifications${queryString}`
			)
			.pipe(
				tap((notif) => {
					if (keepSearch) {
						notif = [...this._notifications.getValue(), ...notif];
					}
					this._notifications.next(notif);
				})
			);
	}

	markAsRead(
		notificationId: number,
		userId: number,
		pageSize?: number,
		pageNumber?: number,
		sortBy?: SortBy
	) {
		if (!this._notifications || this._notifications.value.length <= 0) {
			this.fetchNotifications(userId, pageSize, pageNumber, sortBy, true);
		}
		const updatedNotificationIndex = this._notifications.value.findIndex(
			(pl) => pl.id === notificationId
		);

		return this.http
			.post<UserNotification>(
				environment.notificationAPI + '/users/' + userId + '/notifications/' + notificationId,
				null
			)
			.pipe(
				tap((notif) => {
					this._notifications.value[updatedNotificationIndex].dismissedDate = new Date();
				})
			);
	}

	markAllAsRead(userId: number, pageSize?: number, pageNumber?: number, sortBy?: SortBy) {
		if (!this._notifications || this._notifications.value.length <= 0) {
			this.fetchNotifications(userId, pageSize, pageNumber, sortBy, true);
		}
		return this.http
			.post<UserNotification>(
				environment.notificationAPI + '/users/' + userId + '/notifications',
				null
			)
			.pipe(
				tap(() => {
					this.fetchNotifications(userId, pageSize, pageNumber, sortBy, true);
				})
			);
	}
}
