import { Component, Input, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

type TankLevelRange = {
	min: number;
	max: number;
};

@Component({
	selector: 'app-tank-level',
	templateUrl: './tank-level.component.html',
	styleUrls: ['./tank-level.component.scss'],
})
export class TankLevelComponent implements OnChanges {
	@Input() unit: string;
	@Input() maxValue: number;
	@Input() tankLevel: number;

	imagesUrl = environment.imagesUrl;

	calculatedTankLevelPercent: number;

	activeTank: number | null = 0;

	tankLevels: TankLevelRange[] = new Array(10).fill(undefined).map((_, index) => {
		if (index === 0) {
			return {
				min: 1,
				max: 10,
			};
		}
		return {
			min: index * 10 + 1,
			max: ++index * 10,
		};
	});

	constructor() {}

	ngOnChanges() {
		if (Number(this.tankLevel) > Number(this.maxValue) || this.tankLevel < 0) this.tankLevel = 0;
		this.calculateTankLevel();
	}

	calculateTankLevel() {
		this.calculatedTankLevelPercent = (100 * this.tankLevel) / this.maxValue;
	}

	checkTankLevel(tankLevel: TankLevelRange, index: number): number {
		if (
			this.calculatedTankLevelPercent >= tankLevel.min &&
			this.calculatedTankLevelPercent <= tankLevel.max
		) {
			const levelPercent = (this.calculatedTankLevelPercent - tankLevel.min) * 10;
			this.activeTank = index;
			return (levelPercent / 100) * 15 - 28;
		}
		if (this.calculatedTankLevelPercent > tankLevel.max) {
			this.activeTank = index;
			return 0;
		}
		return -100;
	}

	levelReportTopProperty(): number {
		const topPropertyMath = 245 - (this.calculatedTankLevelPercent / 100) * 245 + 3;
		if (topPropertyMath <= 14.3) {
			return 14.4;
		}
		if (topPropertyMath >= 242) {
			return 242;
		}
		return topPropertyMath;
	}
}
