import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandlerService } from 'factory-ng';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class OperationIndicatorsReportServiceService {
	constructor(
		private httpClient: HttpClient,
		private messageService: MessageHandlerService,
		private translateService: TranslateService
	) {}

	public downloadReportExcel(): Observable<HttpResponse<Blob>> {
		return this.httpClient
			.get(`${environment.businessAPI}/reports/OperationIndicators`, {
				observe: 'response',
				responseType: 'blob',
			})
			.pipe(
				catchError((error) =>
					this.messageService.showErrorAndThrow(
						error,
						this.translateService.instant('messages.fetchOperationIndicatorsError')
					)
				)
			);
	}
}
